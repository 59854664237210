import React, { useEffect, useState } from 'react';
import styles from './AIEvents.module.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Calendar from './Calender';
import EventFilter from './EventFilter';
import EventCard from './EventCard';
import { useNavigate } from 'react-router-dom';
import { getAiModels } from '../../services/aiCamera';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AIEvents = () => {
  const [models, setModel] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [events, setEvents] = useState([]);

  // Get today's date
  const today = new Date();
  const formattedTodayDate = today.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
  const dayName = today.toLocaleDateString('en-US', { weekday: 'short' });

  const eventDates = [
    { date: formattedTodayDate, day: dayName },
    { date: '16 Jul', day: 'Mon' },
    { date: '17 Jul', day: 'Tue' },
    { date: '18 Jul', day: 'Wed' },
    { date: '20 Jul', day: 'Thu' },
    { date: '21 Jul', day: 'Fri' },
    { date: '22 Jul', day: 'Sat' },
  ];

  const navigate = useNavigate();

  const arcisClick = () => {
    navigate('/arcisgpt');
  };

  const notificationClick = () => {
    navigate('/notification');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAiModels();
        setModel(response.models);
        console.log('AI Models:', response);
      } catch (error) {
        console.error('Error fetching AI models:', error);
      }
    };

    fetchData();
  }, []);

  const handleModelChange = (model) => {
    setSelectedModel(model);
  };

  // const handleEventsUpdate = (newEvents) => {
  //   setEvents(newEvents);
  //   console.log('Updated Events:', newEvents);
  // };
  const handleEventsUpdate = (newEvents) => {
    if (!newEvents || newEvents.length === 0) {
      toast.error('No events available');
    } else {
      setEvents(newEvents);
      console.log('Updated Events:', newEvents);
    }
  };


  return (
    <main className={styles.eventsDesktop}>
      <ToastContainer />
      {/* <Header /> */}
      <div className={styles.contentWrapper}>
        {/* <Sidebar /> */}
        <section className={styles.mainContent}>
          <h1 className={styles.pageTitle}>AI Events</h1>
          <p className={styles.pageDescription}>
            List of events triggered by cameras
          </p>
          <div className={styles.tabGroup}>
            <button className={`${styles.tab} ${styles.active}`}>Events</button>
            <button className={styles.tab} onClick={notificationClick}>Notifications</button>
            <button className={styles.tab}>Reports</button>
            <button className={styles.tab} onClick={arcisClick}>
              ArcisGPT
            </button>
          </div>

          <Calendar dates={eventDates} />
          <EventFilter
            model={models}
            onModelChange={handleModelChange}
            onEventsUpdate={handleEventsUpdate}
          />

          {/* {events.length > 0 ? (
            <div className={styles.eventCardGrid}>
              {events.map((event, index) => (
                <EventCard
                  key={index}
                  title={event.title}
                  deviceId={event.deviceId}
                  length={event.length}
                  imageUrl={event.imgurl}
                />
              ))}
            </div>
          ) : (
            <p className={styles.noDataMessage}>No data available</p>
          )} */}
          {events.length > 0 ? (
            <div className={styles.eventCardGrid}>
              {events.map((event, index) => (
                <EventCard
                  key={index}
                  title={event.title}
                  deviceId={event.deviceId}
                  length={event.length}
                  imageUrl={event.imgurl}
                />
              ))}
            </div>
          ) : (
            <p className={styles.noDataMessage}>No data available</p>
          )}
        </section>
      </div>
    </main>
  );
};

export default AIEvents;
