import React, { useEffect, useState, useRef } from 'react';
import styles from './Reports.module.css';
import { SlCalender } from 'react-icons/sl';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import datepicker styles
import MetricGroup from './MetricGroup';
import { getEventList } from '../../services/aiCamera';

function Reports() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const datePickerRef = useRef(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Track current page
  const [loading, setLoading] = useState(false); // Track loading state
  const [hasMore, setHasMore] = useState(true); // Track if there is more data to load

  const handleChange = (date) => {
    if (date) {
      setSelectedDate(date);
      setPage(1); // Reset to page 1 when date changes
      setData([]); // Clear existing data
      setHasMore(true); // Reset hasMore
    }
  };

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
    if (calendarOpen) {
      datePickerRef.current.setOpen(false);
    } else {
      datePickerRef.current.setOpen(true);
    }
  };

  const fetchData = async (page) => {
    try {
      // const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const customerid = localStorage.getItem('userId');
      const itemsPerPage = 5;

      // Convert the selectedDate to local time zone date string (YYYY-MM-DD)
      const localDateString = selectedDate ? selectedDate.toLocaleDateString('en-CA') : '';

      setLoading(true);
      const response = await getEventList(customerid, page, itemsPerPage, localDateString);

      if (response.length > 0) {
        setData(prevData => [...prevData, ...response]); // Append new data
      } else {
        setHasMore(false); // No more data to load
      }

      setLoading(false);
    } catch (err) {
      setError(err.message || 'Something went wrong');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page); // Fetch initial data or when page changes
  }, [page, selectedDate]); // Depend on page and selectedDate

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setPage(prevPage => prevPage + 1); // Increment page number
    }
  };

  return (
    <section className={styles.eventListSection}>
      <MetricGroup />
      <div className={styles.eventListHeader}>
        <div className={styles.dateFilter}>
          <div className={styles.datePickerWrapper}>
            <label htmlFor="dateRange" className="visually-hidden">Select date</label>
            <DatePicker
              selected={selectedDate}
              onChange={handleChange}
              placeholderText="Pick a date"
              className={styles.dateInput}
              ref={datePickerRef}
              open={calendarOpen} // Control the open state
              onClickOutside={() => setCalendarOpen(false)} // Close when clicking outside
              dateFormat="yyyy/MM/dd" // Adjust date format if needed
            />
            <SlCalender className={styles.calender} onClick={toggleCalendar} />
          </div>
          <button className={styles.clearButton} onClick={() => setSelectedDate(new Date())}>Clear</button>
        </div>
        <button className={styles.downloadButton}>
          Download report
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff99f5fde786c9276cd4ad892eea45f794da5112bc0515319430b316e84f56e5?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.downloadIcon} />
        </button>
      </div>
      <ul className={styles.eventList}>
        {data.length > 0 ? (
          data.map((event, index) => (
            <li key={index} className={styles.eventItem}>
              <div className={styles.eventInfo}>
                <div className={styles.serialNumber}>{index + 1}.</div>
                <span className={styles.deviceName}>{event.cameraid}</span>
                <div className={styles.eventType}>
                  <span>{event.modelname}</span>
                </div>
              </div>
              <time className={styles.eventTime}>{event.sendtime}</time>
            </li>
          ))
        ) : (
          <li className={styles.noData}>Data is unavailable for the selected date.</li>
        )}
      </ul>
      {hasMore && (
        <button
          className={styles.loadMoreButton}
          onClick={handleLoadMore} // Handle load more
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Load More'}
        </button>
      )}
    </section>
  );
}

export default Reports;
