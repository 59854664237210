import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button
} from '@chakra-ui/react';
import CanvasModal from './LineCrossCanvas';
import axios from 'axios';
import LineCrossCanvas from './LineCrossCanvas';
import AreaCanvas from './AreaCanvas';

const AreaDetection = ({deviceId}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState();
  const [whiteLightAlarm, setWhiteLightAlarm] = useState();
  const [appMessagePush, setAppMessagePush] = useState();
  const [rtmpPush, setRtmpPush] = useState();
  const [ftpPush, setFtpPush] = useState();
  const [direction, setDirection] = useState('');
  const [RepeatAlarmTime, setRepeatAlarmTime] = useState();
  const [Sensitivity, setSensitivity] = useState();
  const [MinDuration, setMinDuration] = useState();
  const [error, setError] = useState(null);
  const [Action, setAction] = useState('');
  const [DetectRegion, setDetectRegion] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/getAreaDetection',{
          params: {
            deviceId: deviceId
          }});
         
          setIsEnabled(response.data.Enabled)
          setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled)
          setFtpPush(response.data.AlarmOut.FtpPush.Enabled)
          setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled)
          setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled)
          setAppMessagePush(response.data.AlarmOut.AppPush.Enabled)
          setAction(response.data.Action)
          setMinDuration(response.data.MinDuration)
          setRepeatAlarmTime(response.data.RepeatAlarmTime)
          setSensitivity(response.data.Sensitivity)
          setDirection(response.data.Direction)
          setDetectRegion(response.data.DetectRegion);
          console.log(response.data)
        
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]); 

  const handleSave = async () => {
    try {
      const response = await axios.put(`https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/setAreaDetection`, {
        Enabled: isEnabled,
        DetectRegion: DetectRegion,
        DetectObj: 'Human',
        Direction: direction,
        AlarmOut: {
          AudioAlert: {
            Enabled: alarmSound,
          },
          LightAlert: {
            Enabled: whiteLightAlarm,
          },
          AppPush: {
            Enabled: appMessagePush,
          },
          RtmpPush: {
            Enabled: rtmpPush,
          },
          FtpPush: {
            Enabled: ftpPush,
          },
          Email: {
            Enabled: false,
          },
          gat1400: {
            Enabled: false,
          },
        },
        Action:Action,
        MinDuration:MinDuration,
        RepeatAlarmTime:RepeatAlarmTime,
        Sensitivity:Sensitivity
      }, {
        params: { deviceId: deviceId }
      });

      console.log('Settings saved', response.data);
    } catch (error) {
      console.error('Error saving settings', error);
    }
  };


  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };

  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };
  
  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  const handleCanvasData = (region, direction) => {
    setDetectRegion(region);
    setDirection(direction);
  };
  return (
    <Flex direction="column" padding="10px" gap={4}>
          <Box>
            <Text fontWeight="bold" fontSize="lg" color="blue.500">
              Area Detection
            </Text>
          </Box>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable
              </FormLabel>
              <Checkbox isChecked={isEnabled} onChange={(e) => setIsEnabled(e.target.checked)} />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable alarm Sound
              </FormLabel>
              <Checkbox isChecked={alarmSound} onChange={(e) => setAlarmSound(e.target.checked)} />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable white light alarm sound
              </FormLabel>
              <Checkbox isChecked={whiteLightAlarm} onChange={(e) => setWhiteLightAlarm(e.target.checked)} />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable app message push
              </FormLabel>
              <Checkbox isChecked={appMessagePush} onChange={(e) => setAppMessagePush(e.target.checked)} />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable RTMP push
              </FormLabel>
              <Checkbox isChecked={rtmpPush} onChange={(e) => setRtmpPush(e.target.checked)} />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable FTP push
              </FormLabel>
              <Checkbox isChecked={ftpPush} onChange={(e) => setFtpPush(e.target.checked)} />
            </FormControl>
          </Flex>
          
          <Box mt={4}>
        <Button colorScheme="blue" onClick={handleSave}>Save</Button>
      </Box>
      <Box mt={4}>
        <Button colorScheme="teal" onClick={openCanvasModal}>
          Open Canvas
        </Button>
      </Box>
      <AreaCanvas isOpen={isCanvasModalOpen}  onClose={closeCanvasModal} onCanvasData={handleCanvasData} 
      existingAction={Action}
      existingCoordinates={DetectRegion}
      existingDirection={direction}
      deviceId={deviceId}
      />
        </Flex>
  );
};

export default AreaDetection;
