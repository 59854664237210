import styles from './LoginPage.module.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { login, reverify } from '../../services/auth';
import { useToast } from '@chakra-ui/react';

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerificationMessageVisible, setVerificationMessageVisible] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const showToast = (msg, status1) => {
    toast({
      description: msg,
      status: status1,
      duration: 3000,
      position: 'bottom-left',
      isClosable: true,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission

    if (!email || !password) {
      setErrorMessage('Please enter both email and password.');
      return;
    }

    try {
      setErrorMessage('');
      setIsLoading(true); // Show the loader

      // Handle remember me functionality
      if (rememberMe) {
        localStorage.setItem('rememberMe', true);
        localStorage.setItem('email', email);
      } else {
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('email');
      }

      // Call the login function and pass the email and password
      const loginResult = await login(email, password);
      // console.log('Login result:', loginResult);
      if (loginResult.success) {
        localStorage.setItem('email', loginResult.user.email);
        localStorage.setItem('userId', loginResult.user.customerid);
        // localStorage.setItem('userDetails', JSON.stringify(loginResult.user));
        // localStorage.setItem('isLoggedIn', true);
        // localStorage.setItem('token', loginResult.token);
        // localStorage.setItem('langflag', 'en');

        // Redirect to the dashboard page
        navigate('/dashboard');
        showToast('Logged in Successfully', 'success');
      } else {
        setErrorMessage(loginResult.message);
        showToast(loginResult.message, 'error');
      }
    } catch (error) {
      setErrorMessage('Failed to login. Please try again.');
      console.error('Error:', error);
    } finally {
      setIsLoading(false); // Hide the loader
    }
  };

  const verifyHere = async () => {
    setErrorMessage('');
    setIsLoading(true);
    const storedEmail = localStorage.getItem('email');

    try {
      const reverifyResult = await reverify(storedEmail);

      if (reverifyResult.message) {
        setVerificationMessageVisible(true);
        showToast('Verification email sent. Please check your inbox.', 'success');
      } else {
        setErrorMessage('Failed to send verification email.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Failed to send verification email. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotClick = () => {
    // handleForgotClick();
    // Implement forgot password logic here
  };

  return (
    <form className={styles.loginForm} onSubmit={handleLogin}>
      <div className={styles.inputGroup}>
        <label htmlFor="email" className={styles.label}>Email</label>
        <input
          type="email"
          id="email"
          className={styles.input}
          placeholder="Enter your email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="password" className={styles.label}>Password</label>
        <input
          type="password"
          id="password"
          className={styles.input}
          placeholder="••••••••"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className={styles.formOptions}>
        <label className={styles.rememberMe}>
          {/* <input
            type="checkbox"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          /> */}
          {/* <span>Remember for 30 days</span> */}
        </label>
        <button
          type="button"
          onClick={handleForgotClick}
          className={styles.forgotPassword}
        >
          Forgot password
        </button>
      </div>
      {errorMessage && (
        <p className={styles.errorMessage}>{errorMessage}</p>
      )}
      {errorMessage === "Your account is not verified." && (
        <button
          type="button"
          className={styles.verifyButton}
          onClick={verifyHere}
          disabled={isLoading}
        >
          {isLoading ? 'Sending Verification OTP...' : 'Click to Verify'}
        </button>
      )}
      <button
        type="submit"
        className={styles.submitButton}
        disabled={isLoading}
      >
        {isLoading ? 'Logging in...' : 'Sign in'}
      </button>
    </form>
  );
}

export default LoginForm;
