import React, { useState, useEffect } from 'react';
import styles from './Calender.module.css'; // Adjust the import path as needed
import { getAiCameraList } from '../../services/aiCamera';

// Utility function to generate week dates
const getWeekDates = (startDate) => {
  const dates = [];
  const dayMilliseconds = 24 * 60 * 60 * 1000;
  const options = { day: '2-digit', month: 'short' }; // Format for date
  const dayOptions = { weekday: 'long' }; // Format for day of the week
  
  for (let i = 0; i < 7; i++) {
    const date = new Date(startDate.getTime() + i * dayMilliseconds);
    dates.push({
      date: date.toLocaleDateString('en-US', options),
      day: date.toLocaleDateString('en-US', dayOptions),
      fullDate: date.toISOString().split('T')[0], // Store full date for comparison
    });
  }
  return dates;
};

const Calendar = () => {
  const [startDate, setStartDate] = useState(new Date()); // Default to the current week
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Select today's date by default
  const [devices, setDevices] = useState([]); // State to store AI devices

  // const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const customerId = localStorage.getItem('userId');
  let resultPerPage = 1000;
  let page = 1;

  useEffect(() => {
    // Fetch AI devices on component mount
    const fetchDevices = async () => {
      try {
        const response = await getAiCameraList(customerId, page, resultPerPage); // Fetch devices from API
        if (response.success) {
          setDevices(response.cameras); // Assuming the API returns a 'devices' array
        }
      } catch (error) {
        console.error('Error fetching AI devices:', error);
      }
    };

    fetchDevices();
  }, []);

  const currentWeekDates = getWeekDates(startDate);

  const goToPreviousWeek = () => {
    const newStartDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    setStartDate(newStartDate);
  };

  const goToNextWeek = () => {
    const newStartDate = new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    setStartDate(newStartDate);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className={styles.calendarWrapper}>
      <div className={styles.calendarControls}>
        <select id="deviceSelect" className={styles.select}>
          <option value="">All Devices</option>
          {devices.map((device) => (
            <option key={device.deviceid} value={device.deviceid}>
              {device.cameraname}
            </option>
          ))}
        </select>
        <button className={styles.prevWeek} onClick={goToPreviousWeek}>Previous Week</button>
        <button className={styles.nextWeek} onClick={goToNextWeek}>Next Week</button>
      </div>
      <div className={styles.dateList}>
        {currentWeekDates.map((date, index) => (
          <div
            key={index}
            className={`${styles.dateItem} ${date.fullDate === selectedDate ? styles.selected : ''}`}
            onClick={() => handleDateClick(date.fullDate)}
          >
            <div className={styles.date}>{date.date}</div>
            <div className={styles.day}>{date.day}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
