import React, { useEffect, useRef,useState } from "react";
import styles from './DeviceComponent.module.css';

import DeviceView from './DeviceView';
import Controls from './Control';
import Timeline from "./Timeline";
// import Cloud_Timeline from "./Cloud_Timeline";

function DeviceComponent({device,goBack,status,url: initialUrl}) {

  const [selectedDate, setSelectedDate] = useState(getCurrentISTDate()); // Initialize with today's date in IST
  const [url, setUrl] = useState(initialUrl);
  // Function to get the current date in IST formatted as YYYY-MM-DD
  function getCurrentISTDate() {
    const now = new Date();
    const options = { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = now.toLocaleDateString('en-IN', options);
    return formattedDate.split('/').reverse().join('-'); // Convert DD/MM/YYYY to YYYY-MM-DD
  }
  const handleDateChange = (date) => {
    // Ensure date is a valid Date object
    const dateObj = date instanceof Date ? date : new Date(date);
    if (!isNaN(dateObj.getTime())) {
      // Convert to IST and format date to YYYY-MM-DD
      const options = { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' };
      const formattedDate = dateObj.toLocaleDateString('en-IN', options);
      setSelectedDate(formattedDate.split('/').reverse().join('-'));
    } else {
      console.error("Invalid date:", date);
    }
  };
  
  const updateUrl = (newUrl) => {
    console.log(newUrl)
    setUrl(newUrl);
  };

  useEffect(() => {
    setUrl(initialUrl); // Update local URL state if initialUrl changes
  }, [initialUrl]);

  const [isOn, setIsOn] = useState(false);

  const toggle = () => {
    setIsOn(!isOn);
  };

  return (
    <>
      <div className={styles.dashboardContainer}>
      {/* <Header /> */}
      <main className={styles.mainContent}>
        {/* <Sidebar /> */}
        <section className={styles.deviceSection}>
          <DeviceView url={url} device={device} goBack={goBack} status={status} onDateChange={handleDateChange}/>
            <div className={styles.iconContainer}>
              <div
                  className={`${styles.sdIcon} ${isOn ? styles.iconOn : styles.iconOff}`}
                  onClick={toggle}
                >
                  <div className={styles.ic}>SD</div>
                </div>
                <div
                  className={`${styles.cloudIcon} ${!isOn ? styles.iconOn : styles.iconOff}`}
                  onClick={toggle}
                >
                <div className={styles.ic}>Cloud</div>
                </div>
            </div>
            {isOn ? (
              <Timeline date={selectedDate} plan={device.planname} deviceid={device.deviceid} onUrlChange={updateUrl} />
            ) : (
              <Timeline date={selectedDate} plan={device.planname} deviceid={device.deviceid} onUrlChange={updateUrl} />
            )}
            {/* <Cloud_Timeline date={selectedDate} deviceid={device.deviceid} onUrlChange={updateUrl}/> */}
          {/* <Timeline date={selectedDate} deviceid={device.deviceid} onUrlChange={updateUrl}/> */}
          <Controls device={device}/>
        </section>
      </main>
    </div>
    
  </>
  );
}

export default DeviceComponent;

