import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button,
  VStack,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
} from '@chakra-ui/react';

import axios from 'axios';


const SimNetwork = ({deviceId}) => {
   
  return (

    <Flex direction="column" padding="10px" gap={4}>
    <Box>
      <Text fontWeight="bold" fontSize="lg" color="blue.500">
        4G Network Settings
      </Text>
    </Box>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0">IMEI</FormLabel>
        <Input value="" width="auto" />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0">ICCID</FormLabel>
        <Input value="" width="auto" />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0">Signal Value(dBm)</FormLabel>
        <Input value="" width="auto" />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0">OutBanWidth(B/s)</FormLabel>
        <Input value="" width="auto" />
      </FormControl>
    </Flex>
  </Flex>
    
);
};

export default SimNetwork;