import React, { useState } from 'react';
import {
  Box,
  Text,
  Badge,
  Image,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  VStack,
  Flex,
  Select,
  FormControl,
  FormLabel,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Checkbox,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Heading
} from '@chakra-ui/react';
import { FiMoreVertical, FiShare, FiTrash2, FiSettings, FiChevronLeft, FiChevronRight, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import LiveFeed from './LiveFeed-Hls';
import  Psettings from './Psettings';
import JessibucaPlayer from "react-jessibuca"
import SDCardDataTable from './SDCardDataTable';
import { getSDCardStatus,getSDCardData } from '../services/sdcard';
const CardModel = () => {

  const camera = {
    cameraname: 'Static Camera',
    deviceid: 'web1',
    thumbnailUrl: 'https://via.placeholder.com/600x342/000000/?text=Camera+Thumbnail',
    islive: true,
    streamname: 'example_stream',
    cameraurl: 'https://web1.torqueverse.dev/flv/live_ch0_1.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==',
    planname: 'example_plan',
    cameraid: '789012',
  };

  const [sdCardData, setSDCardData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSdCardModalOpen, setIsSdCardModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('live');
  const [selectedSpeed, setSelectedSpeed] = useState('1');
  const [selectedCruise, setSelectedCruise] = useState('all round');
  const [presetValue, setPresetValue] = useState('');
  const [zoomValue, setZoomValue] = useState(50); // State for zoom value (default: 50)
  const [focusValue, setFocusValue] = useState(50); // State for focus value (default: 50)
  const [apertureValue, setApertureValue] = useState(50); // State for aperture value (default: 50)
  const [selectedMediaOption, setSelectedMediaOption] = useState('Video Setup');
  const [selectedNetworkOption, setSelectedNetworkOption] = useState('Local Setup');
  const [selectedAIOption, setSelectedAIOption] = useState('Motion Detection');

  const [microphoneType, setMicrophoneType] = useState('');
  const [profile, setProfile] = useState('');
  const [encodeType, setEncodeType] = useState('');
  const [stream, setStream] = useState('');
  const [resolution, setResolution] = useState('');
  const [bitrateControlType, setBitrateControlType] = useState('');
  const [channelName, setChannelName] = useState('');
  const [bps, setBps] = useState('');
  const [fps, setFps] = useState('');

  const [contrast, setContrast] = useState(50);
  const [brightness, setBrightness] = useState(50);
  const [saturation, setSaturation] = useState(50);
  const [hue, setHue] = useState(50);
  const [sharpen, setSharpen] = useState(50);

  const [flipChecked, setFlipChecked] = useState(false);
  const [mirrorChecked, setMirrorChecked] = useState(false);

  const [checkedItems, setCheckedItems] = useState({
    Timer: false,
    Motion: false,
    Human: false,
    Face: false,
    Linecross: false,
    RegionEnter: false,
    RegionExit: false,
    Intrude: false,
    Unattend: false,
    ObjRemove: false,
  });

  const handleSelectAll = () => {
    const allChecked = Object.keys(checkedItems).reduce((items, item) => {
      items[item] = true;
      return items;
    }, {});
    setCheckedItems(allChecked);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleContrastChange = (value) => {
    setContrast(value);
    // Implement logic to update contrast
    console.log('Contrast value:', value);
  };

  const handleBrightnessChange = (value) => {
    setBrightness(value);
    // Implement logic to update brightness
    console.log('Brightness value:', value);
  };

  const handleSaturationChange = (value) => {
    setSaturation(value);
    // Implement logic to update saturation
    console.log('Saturation value:', value);
  };

  const handleHueChange = (value) => {
    setHue(value);
    // Implement logic to update hue
    console.log('Hue value:', value);
  };

  const handleSharpenChange = (value) => {
    setSharpen(value);
    // Implement logic to update sharpen
    console.log('Sharpen value:', value);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [error, setError] = useState(null);
  const handleOpenSdCardModal = async () => {    
    setIsSdCardModalOpen(true);
  };

  const handleCloseSdCardModal = () => {
    setIsSdCardModalOpen(false);
  };

  const handleOpenSettingsModal = () => {
    setIsSettingsModalOpen(true);
  };

  const handleCloseSettingsModal = () => {
    setIsSettingsModalOpen(false);
  };

  const handleShareCameraModel = (cameraname, cameraid) => {
    console.log(`Sharing camera ${cameraname} with ID ${cameraid}`);
    // Implement share functionality as needed
  };

  const handleDeleteCamera = (cameraid) => {
    console.log(`Deleting camera with ID ${cameraid}`);
    // Implement delete functionality as needed
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  const handleJoystickClick = (direction) => {
    console.log(`Moving camera ${direction} at speed ${selectedSpeed}`);
    // Implement camera movement logic here with selected speed
  };

  const handleSpeedChange = (event) => {
    setSelectedSpeed(event.target.value);
  };

  const handleCruiseChange = (event) => {
    setSelectedCruise(event.target.value);
  };

  const handlePresetChange = (event) => {
    setPresetValue(event.target.value);
  };

  const handleZoomChange = (value) => {
    setZoomValue(value);
    // Implement logic to update camera zoom
    console.log('Zoom value:', value);
  };

  const handleFocusChange = (value) => {
    setFocusValue(value);
    // Implement logic to update camera focus
    console.log('Focus value:', value);
  };

  const handleApertureChange = (value) => {
    setApertureValue(value);
    // Implement logic to update camera aperture
    console.log('Aperture value:', value);
  };

  const handleFlipChange = (e) => {
    setFlipChecked(e.target.checked);
    // Implement logic for flip checkbox change
  };

  const handleMirrorChange = (e) => {
    setMirrorChecked(e.target.checked);
    // Implement logic for mirror checkbox change
  };

  const renderMediaContent = () => {
    switch (selectedMediaOption) {
      case 'Video Setup':
        return (
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Microphone Type</FormLabel>
              <Select value={microphoneType} onChange={(e) => setMicrophoneType(e.target.value)}>
                <option value="Active Pickup">Active Pickup</option>
                <option value="Passive Mic">Passive Mic</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Profile</FormLabel>
              <Select value={profile} onChange={(e) => setProfile(e.target.value)}>
                <option value="H.264">H.264</option>
                <option value="H.265">H.265</option>
                <option value="H.264+">H.264+</option>
                <option value="H.265+">H.265+</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Encode Type</FormLabel>
              <Select value={encodeType} onChange={(e) => setEncodeType(e.target.value)}>
                <option value="video">video</option>
                <option value="audio&video">audio&video</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Stream</FormLabel>
              <Select value={stream} onChange={(e) => setStream(e.target.value)}>
                <option value="main stream">main stream</option>
                <option value="substream1">substream1</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Resolution</FormLabel>
              <Select value={resolution} onChange={(e) => setResolution(e.target.value)}>
                <option value="2304x1296">2304x1296</option>
                <option value="1920x1080">1920x1080</option>
                <option value="1280x720">1280x720</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Bitrate Control Type</FormLabel>
              <Select value={bitrateControlType} onChange={(e) => setBitrateControlType(e.target.value)}>
                <option value="vbr">VBR</option>
                <option value="cbr">CBR</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Channel Name</FormLabel>
              <Input type="text" value={channelName} onChange={(e) => setChannelName(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>BPS</FormLabel>
              <Input type="text" value={bps} onChange={(e) => setBps(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>FPS</FormLabel>
              <Input type="text" value={fps} onChange={(e) => setFps(e.target.value)} />
            </FormControl>
          </VStack>
        );
      case 'Image Setup':
        return (
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Contrast</FormLabel>
              <Slider defaultValue={contrast} min={0} max={100} step={1} onChange={handleContrastChange}>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </FormControl>
            <FormControl>
              <FormLabel>Brightness</FormLabel>
              <Slider defaultValue={brightness} min={0} max={100} step={1} onChange={handleBrightnessChange}>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </FormControl>
            <FormControl>
              <FormLabel>Saturation</FormLabel>
              <Slider defaultValue={saturation} min={0} max={100} step={1} onChange={handleSaturationChange}>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </FormControl>
            <FormControl>
              <FormLabel>Hue</FormLabel>
              <Slider defaultValue={hue} min={0} max={100} step={1} onChange={handleHueChange}>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </FormControl>
            <FormControl>
              <FormLabel>Sharpen</FormLabel>
              <Slider defaultValue={sharpen} min={0} max={100} step={1} onChange={handleSharpenChange}>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </FormControl>
            <FormControl>
              <Checkbox isChecked={flipChecked} onChange={handleFlipChange}>
                Flip
              </Checkbox>
            </FormControl>
            <FormControl>
              <Checkbox isChecked={mirrorChecked} onChange={handleMirrorChange}>
                Mirror
              </Checkbox>
            </FormControl>
          </VStack>
        );
      case 'ISP':
        return (
          <Flex direction="column" padding="10px" align="start" gap={4}>
          <Box>
            <Select placeholder="Software Ircut control" disabled="true">
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </Select>
          </Box>
          <Box>
            <Select placeholder="Ir cut mode">
              <option value="irledmode">irledmode</option>
              <option value="lightmode">lightmode</option>
              <option value="smartmode">smartmode</option>
              <option value="daylight">daylight</option>
              <option value="night">night</option>
            </Select>
          </Box>
          <Box>
            <Checkbox>Digital width dynamic setting</Checkbox>
          </Box>
        </Flex>
        );
      case 'Privacy Mask':
        return (
          <Flex direction="column" padding="10px" align="start" gap={4}>
          <Box>
            <Checkbox>Enable Privacy Mask</Checkbox>
          </Box>
          <Box>
            <Select placeholder="Color">
              <option value="green">green</option>
              <option value="white">white</option>
              <option value="blue">blue</option>
            </Select>
          </Box>
          <Box>
            <Button colorScheme="blue">Apply</Button>
          </Box>
        </Flex>
        );
      case 'OSD':
        return (
          <Flex direction="row" padding="10px" align="start" gap={8}>
          <Box>
            <Flex direction="column" gap={4}>
              <Text fontWeight="bold" color="blue.500" fontSize="lg">
                Date Time Overlay
              </Text>
              <Box>
                <Checkbox>Enable</Checkbox>
              </Box>
              <Box>
                <Flex direction="row" gap={2}>
                  <Input placeholder="Region X/Region Y" type="number" />
                </Flex>
              </Box>
              <Box>
                <Select placeholder="Select Date Format">
                  <option value="dd-mm-yyyy">DD-MM-YYYY</option>
                  <option value="mm-dd-yyyy">MM-DD-YYYY</option>
                  <option value="yyyy-mm-dd">YYYY-MM-DD</option>
                </Select>
              </Box>
              <Box>
                <Select placeholder="Select Time Format">
                  <option value="12-hour">12-hour</option>
                  <option value="24-hour">24-hour</option>
                </Select>
              </Box>
              <Box>
                <Checkbox>Display Week</Checkbox>
              </Box>
              <Box>
                <Button colorScheme="blue">Apply</Button>
              </Box>
            </Flex>
          </Box>
          <Box>
            <Flex direction="column" gap={4}>
              <Text fontWeight="bold" color="blue.500" fontSize="lg">
                Channel Name Overlay
              </Text>
              <Box>
                <Checkbox>Enable</Checkbox>
              </Box>
              <Box>
                <Flex direction="row" gap={2}>
                  <Input placeholder="Region X/Region Y" type="number" />
                </Flex>
              </Box>
              {/* Add additional elements for Channel Name Overlay here */}
            </Flex>
          </Box>
        </Flex>
        );
      default:
        return null;
    }
  };

const renderNetworkContent = () => {
  switch (selectedNetworkOption) {
    case 'Local Setup':
      return (
        <Flex direction="column" padding="10px" gap={4}>
        <Box>
          <Text fontWeight="bold" fontSize="lg" color="blue.500">
            Local Setup
          </Text>
        </Box>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0">MAC Address</FormLabel>
            <Input value="9c:a3:a9:dc:8e:6b" isDisabled width="auto" />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>IP Adapted</FormLabel>
            <Checkbox>Open</Checkbox>
            <Checkbox>Close</Checkbox>
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>DHCP Switch</FormLabel>
            <Checkbox>Open</Checkbox>
            <Checkbox>Close</Checkbox>
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel fontWeight="bold" marginBottom="0">IP Address</FormLabel>
            <Input value="192.168.1.22" width="auto" />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel fontWeight="bold" marginBottom="0">Gateway</FormLabel>
            <Input value="192.168.1.1" width="auto" />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel fontWeight="bold" marginBottom="0">Netmask</FormLabel>
            <Input value="255.255.255.0" width="auto" />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel fontWeight="bold" marginBottom="0">Preferred DNS</FormLabel>
            <Input value="192.168.1.1" width="auto" />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel fontWeight="bold" marginBottom="0">Alternate DNS</FormLabel>
            <Input value="8.8.8.8" width="auto" />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel fontWeight="bold" marginBottom="0">Port</FormLabel>
            <Input value="80" width="auto" />
          </FormControl>
        </Flex>
        <Box mt={4}>
        <Button colorScheme="blue">Save</Button>
      </Box>
      </Flex>
      );
    case 'RTMP Setup':
      return (
        <Flex direction="column" padding="10px" gap={4}>
          <Box>
            <Text fontWeight="bold" fontSize="lg" color="blue.500">
              RTMP Setup
            </Text>
          </Box>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                RTMP Switch
              </FormLabel>
              <Checkbox />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">RTMP URL</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">
                Stream
              </FormLabel>
              <Select width="auto" placeholder="Select Gateway">
                <option value="MainStream">main stream</option>
                <option value="Substream">sub stream1</option>
              </Select>
            </FormControl>
          </Flex>
          <Box mt={4}>
        <Button colorScheme="blue">Save</Button>
      </Box>
        </Flex>
      );
    case 'FTP Setting':
      return (
        <Flex direction="column" padding="10px" gap={4}>
        <Box>
          <Text fontWeight="bold" fontSize="lg" color="blue.500">
            FTP Setup
          </Text>
        </Box>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              FTP Switch
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              FTP Synchronization Switch
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0">
              FTP Synchronization Type
            </FormLabel>
            <Select width="auto" placeholder="Select Gateway">
              <option value="Sync all recordings by time(days)">Sync all recordings by time(days)</option>
              <option value="Sync all recordings">Sync all recordings</option>
            </Select>
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">FTP Custom Synchronization Time</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              FTP Schedule Switch
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">FTP Port</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">FTP user name</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">FTP Password</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">FTP Server IP(domain name)</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">FTP upload directory</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">FTP upload video path</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">FTP upload video path</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">FTP upload picture path</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">FTP upload time interval(in seconds)</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
  <FormControl display="flex" alignItems="center">
    <FormLabel marginBottom="0">FTP Upload Way</FormLabel>
    <Select width="auto" placeholder="Select FTP Upload Way">
      <option value="Alarm">Alarm</option>
      <option value="Time">Time</option>
    </Select>
  </FormControl>
</Flex>
          <Flex direction="row" align="center" gap={4}>
  <FormControl display="flex" alignItems="center">
    <FormLabel marginBottom="0">FTP Upload Content</FormLabel>
    <Select width="auto" placeholder="Select FTP Upload Way">
      <option value="Upload Picture">Upload Picture</option>
      <option value="Upload Video">Upload Video</option>
      <option value="Upload Picture and Video">Picture and Video</option>
    </Select>
  </FormControl>
</Flex>
<Flex gap={1}>
        <Box mt={4} >
      <Button colorScheme="blue">Test</Button>
    </Box>
        <Box mt={4}>
      <Button colorScheme="blue">Save</Button>
    </Box>
</Flex>
      </Flex>
      );
    case '4G Network Settings':
      return (
        <Flex direction="column" padding="10px" gap={4}>
          <Box>
            <Text fontWeight="bold" fontSize="lg" color="blue.500">
              4G Network Settings
            </Text>
          </Box>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">IMEI</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">ICCID</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">Signal Value(dBm)</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">OutBanWidth(B/s)</FormLabel>
              <Input value="" width="auto" />
            </FormControl>
          </Flex>
        </Flex>
      );
    default:
      return null;
  }
}

const renderAIContent = () => {
  switch(selectedAIOption) {
    case "Motion Detection":
      return  (
        <Flex direction="column" padding="10px" gap={4}>
          <Box>
          <Text fontWeight="bold" fontSize="lg" color="blue.500">
              Motion Detection
            </Text>
          </Box>
          <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enabled
            </FormLabel>
            <Checkbox />
          </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">Sensitivity Level</FormLabel>
              <Input value="" width="auto" />
              <Text>(0-100)</Text>
            </FormControl>
          </Flex>
          <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable Alarm Sound
            </FormLabel>
            <Checkbox />
          </FormControl>
          </Flex>
          <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable white light Sound
            </FormLabel>
            <Checkbox />
          </FormControl>
          </Flex>
        </Flex>
      )
    case "Humanoid Detection":
      return (
        <Flex direction="column" padding="10px" gap={4}>
          <Box>
            <Text fontWeight="bold" fontSize="lg" color="blue.500">
              Humanoid Detection
            </Text>
          </Box>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable
              </FormLabel>
              <Checkbox />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable alarm Sound
              </FormLabel>
              <Checkbox />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable white light alarm sound
              </FormLabel>
              <Checkbox />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable app message push
              </FormLabel>
              <Checkbox />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable RTMP push
              </FormLabel>
              <Checkbox />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable FTP push
              </FormLabel>
              <Checkbox />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Humanoid Draw Region
              </FormLabel>
              <Checkbox />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">Sensitivity Level</FormLabel>
              <Select width="auto" placeholder="Sensitivity Level">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </Select>
            </FormControl>
          </Flex>
          <Box mt={4}>
        <Button colorScheme="blue">Save</Button>
      </Box>
        </Flex>
      );
    case "Face Detection":
      return  (
        <Flex direction="column" padding="10px" gap={4}>
        <Box>
          <Text fontWeight="bold" fontSize="lg" color="blue.500">
            Face Detection
          </Text>
        </Box>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable alarm Sound
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable white light alarm sound
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable app message push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable RTMP push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable FTP push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Face Draw Region
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0">Sensitivity Level</FormLabel>
            <Select width="auto" placeholder="Sensitivity Level">
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </Select>
          </FormControl>
        </Flex>
        <Box mt={4}>
      <Button colorScheme="blue">Save</Button>
    </Box>
      </Flex>
      )
    case "Line Crossing Detection":
      return  (
        <Flex direction="column" padding="10px" gap={4}>
        <Box>
          <Text fontWeight="bold" fontSize="lg" color="blue.500">
            Face Detection
          </Text>
        </Box>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable alarm Sound
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable white light alarm sound
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable app message push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable RTMP push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable FTP push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0">Direction</FormLabel>
            <Select width="auto" placeholder="Sensitivity Level">
              <option value="A->B">AtoB</option>
              <option value="A<-B">BtoA</option>
              <option value="Both">Both</option>
            </Select>
          </FormControl>
        </Flex>
        <Box mt={4}>
      <Button colorScheme="blue">Save</Button>
    </Box>
      </Flex>
      )
    case "Area Detection":
      return  (
        <Flex direction="column" padding="10px" gap={4}>
        <Box>
          <Text fontWeight="bold" fontSize="lg" color="blue.500">
            Face Detection
          </Text>
        </Box>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable alarm Sound
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable white light alarm sound
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable app message push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable RTMP push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable FTP push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0">Action</FormLabel>
            <Select width="auto" placeholder="Action">
              <option value="Inside">Inside</option>
              <option value="EnterOrExit">EnterOrExit</option>
            </Select>
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0">Direction</FormLabel>
            <Select width="auto" placeholder="Direction">
              <option value="Enter">Enter</option>
              <option value="Exit">Exit</option>
              <option value="EnterOrExit">EnterOrExit</option>
            </Select>
          </FormControl>
        </Flex>
        <Box mt={4}>
      <Button colorScheme="blue">Save</Button>
    </Box>
      </Flex>
      )
    case "Customer Traffic Statistics":
      return (
        <Flex direction="column" padding="10px" gap={4}>
          <Box>
            <Text fontWeight="bold" fontSize="lg" color="blue.500">
              Face Detection
            </Text>
          </Box>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Enable
              </FormLabel>
              <Checkbox />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Real-Time Display of Customer Traffic
              </FormLabel>
              <Checkbox />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Reference line angle setting
              </FormLabel>
              <Input placeholder="Enter angle" width="auto" />
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0" mr={4}>
                Reference line width setting
              </FormLabel>
              <Button size="sm" colorScheme="blue" mr={2}>
                Enlarge
              </Button>
              <Button size="sm" colorScheme="blue">
                Reduce
              </Button>
            </FormControl>
          </Flex>
          <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">Direction</FormLabel>
              <Select width="auto" placeholder="Direction">
                <option value="AtoB">AtoB</option>
                <option value="BtoA">BtoA</option>
              </Select>
            </FormControl>
          </Flex>
          <Box mt={4}>
            <Button colorScheme="blue">Save</Button>
          </Box>
        </Flex>
      );
    case "Unattend Baggage Detection":
      return  (
        <Flex direction="column" padding="10px" gap={4}>
        <Box>
          <Text fontWeight="bold" fontSize="lg" color="blue.500">
            Unattend Baggage Detection
          </Text>
        </Box>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable alarm Sound
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable white light alarm sound
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable app message push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable RTMP push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable FTP push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
             Minimum Duration(s)
            </FormLabel>
            <Input placeholder="Enter Duration 0-60" width="auto"/>
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">Sensitivity Level</FormLabel>
              <Select width="auto" placeholder="Sensitivity Level">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </Select>
            </FormControl>
          </Flex>
        <Box mt={4}>
      <Button colorScheme="blue">Save</Button>
    </Box>
        </Flex>
      )
    case "Missing Object Detection":
      return  (
        <Flex direction="column" padding="10px" gap={4}>
        <Box>
          <Text fontWeight="bold" fontSize="lg" color="blue.500">
          Missing Object Detection
          </Text>
        </Box>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable alarm Sound
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable white light alarm sound
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable app message push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable RTMP push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
              Enable FTP push
            </FormLabel>
            <Checkbox />
          </FormControl>
        </Flex>
        <Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel marginBottom="0" mr={4}>
             Minimum Duration(s)
            </FormLabel>
            <Input placeholder="Enter Duration 0-60" width="auto"/>
          </FormControl>
        </Flex>
        <Flex direction="row" align="center" gap={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel marginBottom="0">Sensitivity Level</FormLabel>
              <Select width="auto" placeholder="Sensitivity Level">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </Select>
            </FormControl>
          </Flex>
        <Box mt={4}>
      <Button colorScheme="blue">Save</Button>
    </Box>
        </Flex>
      )
    case "Alarm Schedule":
      return  (
        <Text>Alarm Schedule</Text>
      )
    case "Detection Area":
      return  (
        <Text>Detection Area</Text>
      )
    case "AlarmTone":
      return  (
        <Text>AlarmTone</Text>
      )
  }
}

  return (
    <>
      <Box
        bg="rgba(255, 255, 255, 0.1)"
        backdropFilter="blur(10px)"
        borderRadius="md"
        p={2}
        mb={2}
        boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
        border="1px solid rgba(255, 255, 255, 0.3)"
        transition="transform 0.3s, box-shadow 0.3s"
        _hover={{
          transform: "scale(1.03)",
          boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.3)",
        }}
      >
        <Box position="relative" cursor="pointer">
          <Image
            src={camera.thumbnailUrl}
            alt="Camera"
            size="100%"
            height="auto"
            onClick={handleOpenModal}
          />

          <Text
            position="absolute"
            top="50%"
            left="50%"
            fontSize={25}
            transform="translate(-50%, -50%)"
            color="white"
          >
            ▶
          </Text>

          <Badge
            position="absolute"
            top={2}
            right={2}
            fontSize="sm"
            colorScheme={camera.islive ? "green" : "red"}
          >
            {camera.islive ? "On" : "Off"}
          </Badge>
        </Box>

        <HStack justifyContent="space-between" alignItems="center" py={2}>
          <Text fontWeight="bold" fontSize="sm" p={1}>
            {camera.cameraname}{" "}
            <span style={{ fontSize: "11px", fontWeight: "500" }}>
              ({camera.deviceid})
            </span>
          </Text>

          <Menu placement="top">
            <MenuButton
              as={IconButton}
              icon={<FiMoreVertical />}
              variant="ghost"
            />
            <MenuList>
              <MenuItem
                onClick={() =>
                  handleShareCameraModel(camera.cameraname, camera.cameraid)
                }
                icon={<FiShare />}
              >
                Share This Camera
              </MenuItem>
              <MenuItem
                onClick={() => handleDeleteCamera(camera.cameraid)}
                icon={<FiTrash2 />}
              >
                Remove Camera
              </MenuItem>
              <MenuItem onClick={handleOpenSdCardModal} icon={<FiTrash2 />}>
                SD Card
              </MenuItem>
              <MenuItem onClick={handleOpenSettingsModal} icon={<FiSettings />}>
                Settings
              </MenuItem>
              {/* Add more menu items as needed */}
            </MenuList>
          </Menu>
        </HStack>
      </Box>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="3xl">
        <ModalOverlay />
        <ModalContent bg="white" color="black">
          <ModalHeader>Camera Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <div style={{ width: '100%', height: '500px' }}>
            <JessibucaPlayer
              controls={{
                fullscreen: true,
                screenshot: true,
                play: true,
                audio: true,
                record: true,
              }}
              src={`https://web1.torqueverse.dev/flv/live_ch0_1.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`}
              decoder="/decoder.js"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          </ModalBody>
          <ModalFooter>
            <Text mr={4}>Camera ID: {camera.cameraid}</Text>
            <Button colorScheme="red" onClick={handleCloseModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isSdCardModalOpen}
        onClose={handleCloseSdCardModal}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent bg="white" color="black">
          <ModalHeader>SD Card</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
                
                <SDCardDataTable  data={sdCardData} />
            
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleCloseSdCardModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isSettingsModalOpen}
        onClose={handleCloseSettingsModal}
        size="full"
      >
        <ModalOverlay />
        <ModalContent bg="white" color="black" maxW="90vw" maxH="90vh">
          <ModalHeader>Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" p={6}>
          <Psettings></Psettings>
           
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CardModel;
