// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EventFilter_filterWrapper__Tn-40 {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .EventFilter_deviceFilter__LGIVH,
  .EventFilter_modelFilter__FI4O9 {
    display: flex;
    align-items: center;
  }
  .EventFilter_modelFilter__FI4O9{margin-right: 80%;}
  .EventFilter_select__MBsnW {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background-color: #fff;
    font-size: 14px;
    color: #344054;
  }
  
  .EventFilter_clearButton__slG4u {
    margin-left: 10px;
    background: none;
    border: none;
    color: #585757;
    cursor: pointer;
  }
  .EventFilter_visuallyHidden__MgA5Q {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  @media (max-width: 991px) {
    .EventFilter_filterWrapper__Tn-40 {
      flex-direction: column;
      align-items: flex-start;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/AIEvents/EventFilter.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;;IAEE,aAAa;IACb,mBAAmB;EACrB;EACA,gCAAa,iBAAiB,CAAC;EAC/B;IACE,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;IACtB,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,eAAe;EACjB;EACA;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE;MACE,sBAAsB;MACtB,uBAAuB;IACzB;EACF","sourcesContent":[".filterWrapper {\r\n    margin-top: 20px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n  }\r\n  \r\n  .deviceFilter,\r\n  .modelFilter {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  .modelFilter{margin-right: 80%;}\r\n  .select {\r\n    padding: 10px;\r\n    border-radius: 8px;\r\n    border: 1px solid #d0d5dd;\r\n    background-color: #fff;\r\n    font-size: 14px;\r\n    color: #344054;\r\n  }\r\n  \r\n  .clearButton {\r\n    margin-left: 10px;\r\n    background: none;\r\n    border: none;\r\n    color: #585757;\r\n    cursor: pointer;\r\n  }\r\n  .visuallyHidden {\r\n    position: absolute;\r\n    width: 1px;\r\n    height: 1px;\r\n    padding: 0;\r\n    margin: -1px;\r\n    overflow: hidden;\r\n    clip: rect(0, 0, 0, 0);\r\n    white-space: nowrap;\r\n    border: 0;\r\n  }\r\n  \r\n  @media (max-width: 991px) {\r\n    .filterWrapper {\r\n      flex-direction: column;\r\n      align-items: flex-start;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterWrapper": `EventFilter_filterWrapper__Tn-40`,
	"deviceFilter": `EventFilter_deviceFilter__LGIVH`,
	"modelFilter": `EventFilter_modelFilter__FI4O9`,
	"select": `EventFilter_select__MBsnW`,
	"clearButton": `EventFilter_clearButton__slG4u`,
	"visuallyHidden": `EventFilter_visuallyHidden__MgA5Q`
};
export default ___CSS_LOADER_EXPORT___;
