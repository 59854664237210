import axios from 'axios';
import { parseStringPromise } from 'xml2js';

const baseURL = 'https://iccc.ambicam.com:4000/'; // Set your API base URL here 

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

function getUrlParameters() {
  const urlParams = new URLSearchParams(window.location.search);
  const shareId = urlParams.get('shareId');
  const contentId = urlParams.get('contentId');
  return { shareId, contentId };
}

export async function getAiCameraList(customerid, page, resultPerPage) {
  try {
    // const token = localStorage.getItem('token');
    const response = await instance.get('/camera/getAicamera', {
      params: {
        customerid: customerid,
        page: page,
        resultPerPage: resultPerPage,
      },
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },

    });
    // console.log(response.data)
    return response.data;

  } catch (error) {
    throw error;
  }
}

export async function addAiCamera(customerid, cameraid, ai_name, event, rtmp, selectedCamerastream) {
  try {
    // const token = localStorage.getItem('token');
    const response = await instance.post('/camera/addAicamera', {
      customerid: customerid,
      cameraid: cameraid,
      ai_name: ai_name,
      event: event,
      rtmp: rtmp,
      selectedCamerastream: selectedCamerastream,
    }
    // , {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // }
    );

    return response.data;

  } catch (error) {
    throw error;
  }
}

export async function getAiModels() {
  try {
    // const token = localStorage.getItem('token');
    const response = await axios.get('https://ai1.ambicam.com:7000/get_models');
    // console.log('Response:',response.data)
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export async function getEventList(customerid, page, itemsPerPage, selectedDate) {
  try {
    console.log(selectedDate)
    const response = await instance.post('/analytics/get-ai-data', {
      customerid: customerid,
      pageNumber: page || 1,
      date: selectedDate || new Date().toISOString().split('T')[0] // Pass today's date if no date is selected
    }, {
      params: {
        limit: itemsPerPage || 10
      },
    });
    console.log(response.data);
    return response.data.allData;
  } catch (error) {
    console.error('Error fetching event list:', error);
    throw error;
  }
}

export async function getEventList_AI(modelname, pageNumber, itemsPerPage) {
  try {
    // const userDetailsString = localStorage.getItem("userId");
    // if (!userDetailsString) {
    //   throw new Error("userId not found in localStorage");
    // }
    // const userDetails = JSON.parse(userDetailsString);
    const customerid = localStorage.getItem("userId");
    if (!customerid) {
      throw new Error("customerid not found in user details");
    }
    modelname = modelname.replace(/\.pt$/, '')
    const response = await instance.post(`analytics/get-by-customerid`, {
      modelname: modelname,
      customerid: customerid,
      pageNumber: pageNumber,
      itemsPerPage: itemsPerPage
    }, {
      params: {
        // Authorization: `Bearer ${token}`,
        limit: itemsPerPage
      }
    });
    // Assuming the response contains the data you're interested in
    // console.log(response.data.allData);
    return response.data.allData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Rethrow the error for further handling if necessary
  }
}



// export async function getEventList_AI(modelname, page, itemsPerPage) {
//   try {
//     const token = localStorage.getItem('token');
//     const response = await axios.post('http://142.93.213.150:3000/api/get-ai-type', {
//       modelname: modelname,
//       pageNumber: page,
//     });
//     console.log(response.data)
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// }
//   export async function getEventList_AI(modelname) {
//   try {
//     const token = localStorage.getItem('token');
//     const response = await axios.post('http://142.93.213.150:3000/api/get-ai-type', {
//       modelname: modelname,
//     });
//     console.log(response.data)
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// }

export async function pushagain(cameraid, status) {
  console.log(cameraid, status)
  try {

    const response = await instance.post('/admin/active_ai', {
      cameraid: cameraid,
      status: status,
    }, {
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}