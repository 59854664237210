import React, { useState, useRef, useEffect } from 'react';
import styles from './Dashboard.module.css';
import { SlCalender } from "react-icons/sl";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import datepicker styles
import JessibucaPlayer from 'react-jessibuca';
import Psettings from '../../../components/Psettings';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { CiCircleQuestion } from "react-icons/ci";
import CameraPTZ from '../../../components/CameraPTZ';

function DeviceView({device,goBack,url,onDateChange}) {
  const playerRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const [error, setError] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [playbackSpeed, setPlaybackSpeed] = useState('N/A');
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const datePickerRef = useRef(null);
  const [plantext,setPlantext] = useState('')
  const [deviceId,setDeviceId] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false);
    // Function to open the modal
    const openModal = () => setIsModalOpen(true);

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);
  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
    // if (calendarOpen) {
    //   datePickerRef.current.setOpen(false);
    // } else {
    //   datePickerRef.current.setOpen(true);
    // }
  };

  const handleCameraSettingsClick = (plantext,deviceId) => {

    setPlantext(plantext);
    setDeviceId(deviceId);
    onOpen();
  };

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      onDateChange(date); // Call the passed onDateChange function with the new date
    }
  };


  useEffect(() => {
    const changeHoverText = () => {
      const elements = document.querySelectorAll('.icon-title-tips .icon-title');
      elements.forEach(element => {
        if (element.textContent === '暂停') {
          element.textContent = 'Pause';
        }
        if (element.textContent === '截图') {
          element.textContent = 'ScreenSort';
        }
        if (element.textContent === '录制') {
          element.textContent = 'Video';
        }
        if (element.textContent === '全屏') {
          element.textContent = 'FullScreen';
        }
        if (element.textContent === '退出全屏') {
          element.textContent = 'Exit';
        }
        if (element.textContent === '播放') {
          element.textContent = 'Play';
        }
        if (element.textContent === '停止录制') {
          element.textContent = 'Stop Recording';
        }
        // 退出全屏
      });
    };

    // Create a MutationObserver to monitor changes in the DOM
    const observer = new MutationObserver(() => {
      changeHoverText();
    });

    // Observe the body for changes in its child elements
    observer.observe(document.body, { childList: true, subtree: true });

    // Initial run to catch elements already in the DOM
    changeHoverText();

    // Cleanup the observer when the component is unmounted
    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    const handleError = (err) => {
      console.error('error:', err);
      
      // Safe error handling
      const errorMessage = err?.message || 'An unknown error occurred';
      setError('error: ' + errorMessage);
    };

    const jessibuca = playerRef.current;
    if (jessibuca) {
      jessibuca.on('error', handleError);
    }

    return () => {
      if (jessibuca) {
        jessibuca.off('error', handleError);
      }
    };
  }, []);
  const handleScreenshot = () => {
    try {
      if (playerRef.current) {
        const file = playerRef.current.screenshot('test', 'blob');
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'screenshot.png'; // Set desired filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.warn('Player is not initialized');
      }
    } catch (error) {
      console.error('Screenshot failed:', error.message);
      setError('Screenshot failed: ' + error.message);
    }
  };
  
  return (
    <>
    <section className={styles.deviceView}>
      <svg onClick={goBack} style={{ cursor: 'pointer',marginTop:'5px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M3 19V5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
         <path d="M13 6L7 12L13 18" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
         <path d="M7 12H21" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
       </svg>
      <header className={styles.deviceHeader}>
        <h2 className={styles.deviceTitle}>{device.cameraname}</h2>
        <ar/>
        {/* <time className={styles.deviceTime}>2024-07-11 23:30:02</time> */}
        <div className={styles.dateRangePicker}>
        <div className={styles.datePickerWrapper}>
            <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                className={styles.dateInput}
                ref={datePickerRef}
                open={calendarOpen}
                onClickOutside={() => setCalendarOpen(false)}
              />
            <SlCalender className={styles.calender} onClick={toggleCalendar} />
          </div>
          
          <button aria-label="Calendar" className={styles.iconButton} onClick={openModal}>
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8b985a7dfbf05eda968dc127cb6c1652b11c5541fd1a0c893ad30ddb9b38678?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Calendar" className={styles.icon} />
          </button>
          <button aria-label="Options" className={styles.iconButton}  onClick={() => handleCameraSettingsClick(device.plantext,device.deviceid)}>
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9819f31e80fd0750c673e0721d29706aad946092a4c538a50730be5f79aa9b0a?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Options" className={styles.icon} />
          </button>
        </div>
      </header>

      <div className={styles.deviceComponent}>
        <CameraPTZ 
        deviceId={device.deviceid}/>
      <JessibucaPlayer
            ref={playerRef}
            className={styles.deviceImage}
            controls={true}
            loadingText={'loading'}
            // recorded src={'http://143.244.138.105:5000/flv/dvr2/RTSP-ATPL-900258-AIPTZ/1725087625.flv'}
            src={url}
            // src={`https://${device.deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`}
            decoder="/decoder.js" 
          />

        <div className={`${styles.deviceStatus} ${styles[device.status]}`}>{device.status}</div>
      </div>
    </section>

    {isModalOpen && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <h1 style={{ color: "black" }}>Info:</h1>
              <br/>
              <p style={{ color: "black", fontWeight: "lighter", marginTop: "-10px" }}>
                  <form className={styles.form}>
                        <div className={styles.formGroup}>
                          <label className={styles.label} htmlFor="deviceDescription">Data Usage:</label>
                          <p className={styles.value}>{(device.todayTrafficOut / (1024 ** 3)).toFixed(2)} GB</p>
                        </div>

                        <div className={styles.formGroup}>
                          <label className={styles.label} htmlFor="currentStreaming">Current Streaming:</label>
                          <p className={styles.value}>{device.current_streaming + 1}</p>
                        </div>

                        <div className={styles.formGroup}>
                          <label className={styles.label} htmlFor="lastStartTime">Last Start Time:</label>
                          <p className={styles.value}>{device.lastStartTime}</p>
                        </div>

                        <div className={styles.formGroup}>
                          <label className={styles.label} htmlFor="lastCloseTime">Last Close Time:</label>
                          <p className={styles.value}>{device.lastCloseTime}</p>
                        </div>

                        <div className={styles.buttonGroup}>
                          <button type="button" className={styles.cancelButton} onClick={closeModal}>Close</button>
                        </div>
                  </form>
              </p>
            </div>
          </div>
        )}

    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
    <ModalOverlay />
    <ModalContent >
      <ModalHeader>Camera Settings</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {/* Put your camera settings UI here */} 
        <Psettings onClose={onClose} plantext={plantext} deviceid={device.deviceid}  />
        {/* <CameraSettings onClose={onClose} plantext={plantext} deviceid={deviceId}  /> */}
      </ModalBody>
      
    </ModalContent>
    </Modal>


</>
  );
}

export default DeviceView;
