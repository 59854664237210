import React from 'react';
import styles from './EventCard.module.css';

const EventCard = ({ title, deviceId, length, imageUrl }) => {
  return (
    <article className={styles.eventCard}>
      <span className={styles.duration}>3 mins</span>
      <img src={imageUrl} alt={`Event at ${title}`} className={styles.eventImage} />
      <div className={styles.eventInfo}>
        <h3 className={styles.eventTitle}>{title}</h3>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/06330525eb2816a625fd05da8911e37d85a9e2bf2cdf7d384a4885dec9c65e9a?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.infoIcon} />
      </div>
      <div className={styles.deviceInfo}>
        <span className={styles.deviceId}>{deviceId}</span>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc3664f3d591a9e1b6a22eaf0300eae9db35b233a32b7a40c23f9d960ee58085?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.statusIcon} />
      </div>
      <p className={styles.eventLength}>length : {length}</p>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/5d62710cce8a4ec549ff248e0f791cb7705afd2da880c42d9f39b0746ef1f121?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Play" className={styles.playButton} />
      <span className={styles.connectionStatus}>connected</span>
    </article>
  );
};

export default EventCard;