import React, { useState, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button,
  Input
} from '@chakra-ui/react';
import axios from 'axios';

const OSDSetup = ({ deviceId }) => {
  const [data, setData] = useState({
    datetimeOverlay: {
      enabled: false,
      dateFormat: '',
      timeFormat: '',
      regionX: '',
      regionY: '',
      displayWeek: false,
    },
    channelNameOverlay: {
      enabled: false,
      regionX: '',
      regionY: '',
    },
  });
  const [dateFormat, setDateFormat] = useState('');
  const [timeFormat, setTimeFormat] = useState('');
  const [datetimeOverlayEnabled, setDatetimeOverlayEnabled] = useState(false);
  const [channelNameOverlayEnabled, setChannelNameOverlayEnabled] = useState(false);

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/osd-settings', {
          params: { deviceId: deviceId },
        });
        setData(response.data);
        setDateFormat(response.data.datetimeOverlay.dateFormat);
        setTimeFormat(response.data.datetimeOverlay.timeFormat);
        setDatetimeOverlayEnabled(response.data.datetimeOverlay.enabled);
        setChannelNameOverlayEnabled(response.data.channelNameOverlay.enabled);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [deviceId]);

  const handleDateFormatChange = (e) => setDateFormat(e.target.value);
  const handleTimeFormatChange = (e) => setTimeFormat(e.target.value);
  const handleDatetimeOverlayEnabledChange = (e) => setDatetimeOverlayEnabled(e.target.checked);
  const handleChannelNameOverlayEnabledChange = (e) => setChannelNameOverlayEnabled(e.target.checked);

  const handleOSDClick = async () => {
    const parsedTimeFormat = parseInt(timeFormat, 10) || parseInt(data.datetimeOverlay.timeFormat, 10);

    const requestBody = {
      datetimeOverlay: {
        enabled: datetimeOverlayEnabled,
        dateFormat: dateFormat || data.datetimeOverlay.dateFormat,
        timeFormat: parsedTimeFormat,
        regionX: data.datetimeOverlay.regionX,
        regionY: data.datetimeOverlay.regionY,
        displayWeek: data.datetimeOverlay.displayWeek,
      },
      channelNameOverlay: {
        enabled: channelNameOverlayEnabled,
        regionX: data.channelNameOverlay.regionX,
        regionY: data.channelNameOverlay.regionY,
      },
      deviceIDOverlay: { enabled: false, regionX: 0, regionY: 0 },
    };

    try {
      const response = await axios.put('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/osd-settings', requestBody, {
        params: { deviceId: deviceId },
      });
      console.log('API response:', response);
    } catch (error) {
      console.error('Error making API call:', error);
    }
  };

  return (
    <Flex direction="row" padding="10px" align="start" gap={8}>
      <Box>
        <Flex direction="column" gap={4}>
          <Text fontWeight="bold" color="blue.500" fontSize="lg">
            Date Time Overlay
          </Text>
          <Box>
            <Checkbox isChecked={datetimeOverlayEnabled} onChange={handleDatetimeOverlayEnabledChange}>
              Enable
            </Checkbox>
          </Box>
          <Box>
            <Flex direction="row" gap={2}>
              <Input
                disabled
                placeholder="Region X/Region Y"
                value={`${data.datetimeOverlay.regionX}, ${data.datetimeOverlay.regionY}`}
              />
            </Flex>
          </Box>
          <Box>
            <Select value={dateFormat} onChange={handleDateFormatChange}>
              <option value="DD/MM/YYYY">DD-MM-YYYY</option>
              <option value="MM/DD/YYYY">MM-DD-YYYY</option>
              <option value="YYYY/MM/DD">YYYY-MM-DD</option>
            </Select>
          </Box>
          <Box>
            <Select value={timeFormat} onChange={handleTimeFormatChange}>
              <option value="12">12-hour</option>
              <option value="24">24-hour</option>
            </Select>
          </Box>
          <Box>
            <Checkbox isChecked={data.datetimeOverlay.displayWeek}>
              Display Week
            </Checkbox>
          </Box>
          <Box>
            <Button colorScheme="blue" onClick={handleOSDClick}>
              Apply
            </Button>
          </Box>
        </Flex>
      </Box>
      <Box>
        <Flex direction="column" gap={4}>
          <Text fontWeight="bold" color="blue.500" fontSize="lg">
            Channel Name Overlay
          </Text>
          <Box>
            <Checkbox isChecked={channelNameOverlayEnabled} onChange={handleChannelNameOverlayEnabledChange}>
              Enable
            </Checkbox>
          </Box>
          <Box>
            <Flex direction="row" gap={2}>
              <Input
                disabled
                placeholder="Region X/Region Y"
                value={`${data.channelNameOverlay.regionX}, ${data.channelNameOverlay.regionY}`}
              />
            </Flex>
          </Box>
          {/* Add additional elements for Channel Name Overlay here */}
        </Flex>
      </Box>
    </Flex>
  );
};

export default OSDSetup;
