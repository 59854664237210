import React from 'react';
import { useState } from 'react';
import styles from './Header.module.css';
import { CiCircleQuestion } from "react-icons/ci";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => setIsModalOpen(true);

  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);
  return (
    <header className={styles.header}>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9cadae3544d2e6a3ad6aafa7ad59b08c3216909ee850a29208e6a388b548619a?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Company Logo" className={styles.logo} />
      <nav className={styles.navigation}>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/c70e5bf17d782b8e6692d344b3fa9e7b70599685b3f72659b170d4e932e3026c?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Notification" className={styles.icon} />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/81f0a9dc61b9c74ea77c645e42c02546a3d864000abb057364910a0cb7caf247?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Settings" className={styles.icon} />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/c67124c316db5ffb2d81c5c7649279a0a4cc90bea5fb108354f2ffae59e0a484?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="User Profile" className={styles.icon} />
        <button className={styles.addDeviceButton} onClick={openModal}>+ device</button>
        {isModalOpen && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <h1 style={{color:"black"}}>Add New Device</h1>
              <p style={{color:"black", fontWeight:"lighter", marginTop:"-10px"}}>Give the description below to add your new camera.
              <form className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="deviceName" style={{color:"black", fontWeight:"lighter"}}>Device Name:</label>
          <input 
            id="deviceName" 
            type="text" 
            className={styles.input} style={{position:"relative"}}
          /><CiCircleQuestion style={{position:"absolute", marginTop:"-26px", fontSize:"20px", marginLeft:"82%"}} />
          <span className={styles.helperText}>You will see this name in the dashboard</span>
        </div>
        
        <div className={styles.formGroup}>
          <label htmlFor="deviceDescription" style={{color:"black", fontWeight:"lighter"}}>Device ID:</label>
          <input 
            id="deviceDescription" 
            type="text" 
            className={styles.input} style={{position:"relative"}}
          /><CiCircleQuestion style={{position:"absolute", marginTop:"-26px", fontSize:"20px", marginLeft:"82%"}} />
          <span className={styles.helperText}>Find this ID in your email</span>
        </div>
        
        <div className={styles.buttonGroup}>
          <button type="submit" className={styles.submitButton}>Save Device</button>
          <button type="button" className={styles.cancelButton} onClick={closeModal}>Cancel</button>
        </div>
      </form>
              </p>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;