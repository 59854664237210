
import React, { useEffect, useState } from 'react';
import {
  sendQueryToBackend ,
  chatHistory,
} from "../actions/userActions";
import { Avatar, Box, Button, Card, CardBody, Center, Grid, Heading, Skeleton, Text } from '@chakra-ui/react';
import axios from 'axios';
import { getCardDataByPrompt } from '../../actions/dashboardActions';
const ArcisGPT = () => {

  // const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const customerId = localStorage.getItem('userId');
  const [selectedChat, setSelectedChat] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(false);
  const [selectedprompt, setSelectedPrompt] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const isMobile = window.innerWidth <= 768;
  const widthStyle = isMobile ? '90vw' : '70vw';

  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  // Event listener to update device width on window resize
  const handleResize = () => {
    setDeviceWidth(window.innerWidth);
  };

  // Attach event listener on component mount
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBotTyping, setIsBotTyping] = useState(false); 
  const handleImageClick = (imgUrl) => {
    setSelectedImage(imgUrl);
    setIsModalOpen(true);
  };

  const handleCloseModall = () => {
    setIsModalOpen(false);
  };

  const [showLogo, setShowLogo] = useState(true); // State to track whether to show logo or not
  const [showSampleQuestions, setShowSampleQuestions] = useState(true);

  const [messages, setMessages] = useState([
    // { text: 'Hi there! How can I help you?', sender: 'bot' }
  ]);
  useEffect(() => {
    if (messages.length > 0) {
      setShowLogo(false);
    } else {
      setShowLogo(true);
    }
  }, [messages]);
  const [input, setInput] = useState('');
 

 const handleMessageSubmit = async (e) => {
  e.preventDefault();


  // if (input.trim() === '') return;

  // Add user message to chat history
  const updatedMessages = [...messages, { text: input, sender: 'user' }];
  setMessages(updatedMessages);
  setIsBotTyping(true);

  try {
    let botResponse;

    // Log values to debug
    console.log('Selected Video:', selectedVideo);
    console.log('Image File:', imageFile);

    if (selectedVideo) {
      if (imageFile) {
        console.log('Image File exists:', imageFile);
        const formData = new FormData();
        formData.append('image', imageFile); // Append the image to the form data

        const response = await axios.post('https://ai1.ambicam.com:9900/image-search', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        botResponse = response.data; // Capture the bot's response
        console.log('Image uploaded successfully:', botResponse);
      } else {
        console.log('Image file is missing.');
        botResponse = 'Please select an image to search.';
      }
    } else if (selectedChat) {
      botResponse = await sendQueryToBackend(input, customerId);
      console.log('Chat API called successfully:', botResponse);
    } else if (selectedprompt) {
      botResponse = 'Handling prompt...'; // Placeholder for actual implementation
    } else {
      console.log('No video selected or prompt specified.');
      botResponse = 'No video selected or prompt specified.';
    }

    // Append bot response to chat history while keeping user message intact
    const updatedMessagesWithBotResponse = [...updatedMessages, { text: botResponse, sender: 'bot' }];
    setMessages(updatedMessagesWithBotResponse);

    // Update chat history if necessary
    const response = await chatHistory();
    console.log('Chat history:', response.data);
  } catch (error) {
    console.error('Error handling message submission:', error);
  }

  // Clear input field after submitting message
  setInput('');
  setIsBotTyping(false);
  setShowSampleQuestions(false);
};

  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // console.log('Selected File:', file); // Debugging log
    setImageFile(file);
  };

  const handleCardSubmit = async (message) => {
    // if (message.trim() === '') return;
  
    // // Add user message to chat history
    // const updatedMessages = [...messages, { text: message, sender: 'user' }];
    // setMessages(updatedMessages);
  
    // setIsBotTyping(true);
  
    // try {
    //   // Make API call to the backend with the user query
    //   const botResponse = await sendQueryToBackend(message);
  
    //   // Append bot response to chat history while keeping user message intact
    //   const updatedMessagesWithBotResponse = [...updatedMessages, { text: botResponse, sender: 'bot' }];
    //   setMessages(updatedMessagesWithBotResponse);
    //   const response = await chatHistory();
    //   console.log("chat history :: ", response.data)
    // } catch (error) {
    //   // Handle error if API call fails
    //   console.error('Error handling message submission:', error);
    // }
  
    // // Clear input field after submitting message
    // setInput('');
    // setIsBotTyping(false);
    // setShowSampleQuestions(false);
  };

  const handleDashboardAlertClick = async(input) => {
    const getPromptCardData =  await getCardDataByPrompt(input);
    console.log("getPromptCardData", getPromptCardData)
  } 


  return (
    <div className="chatbot-page-container" style={{ width: widthStyle, marginLeft: "250px" }}>
  <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '10px', overflow: 'hidden' }}>
    <div className="chatbot-messages" style={{ padding: '10px', flex: 1, overflowY: 'auto', marginBottom: '20px' }}>
      {messages.map((msg, index) => {
        // Clean up the text by removing surrounding quotes and trailing commas
        const cleanedText = selectedChat
          ? msg.text.trim().replace(/^['"]+|['",]+$/g, '') 
          : msg.text;

        return (
          <div
            key={index}
            className={`message ${msg.sender}`}
            style={{
              marginBottom: '15px',
              padding: '10px',
              borderRadius: '5px',
              backgroundColor: msg.sender === 'bot' ? '#f0f0f0' : '#4b93bf',
              color: msg.sender === 'bot' ? '#333' : '#fff',
              alignSelf: msg.sender === 'bot' ? 'flex-start' : 'flex-end'
            }}
          >
            {msg.sender === 'user' && (
              <h6>
                <Avatar size="sm" name={'You'} mb="1" mr="2" />
                <strong>You:</strong>
              </h6>
            )}

            {msg.sender === 'bot' && (
              <h6>
                <Avatar size="sm" style={{ borderColor: 'black', flexDirection: 'row', animation: 'upDown 1s infinite alternate' }} />
                <strong>TorqueBot:</strong>
              </h6>
            )}

            {/* Render images directly if selectedVideo is true */}
            {selectedVideo ? (
              msg.images && msg.images.map((url, i) => (
                <img
                  key={i}
                  src={url.replace(/^['"]+|['",]+$/g, '')} // Ensure the URL is clean
                  alt={`Image ${i}`}
                  style={{ maxWidth: '100%', height: 'auto', borderRadius: '5px', display: 'block', marginBottom: '10px' }}
                />
              ))
            ) : (
              // Render text with image URLs if selectedChat is true
              cleanedText.split(' ').map((part, i) => (
                part.includes('https://') && part.includes('.jpg') ? (
                  <img
                    key={i}
                    src={part.replace(/^['"]+|['",]+$/g, '')}
                    alt="Image"
                    style={{ maxWidth: '100%', height: 'auto', borderRadius: '5px', display: 'block', marginBottom: '10px' }}
                  />
                ) : (
                  <span key={i}>{part} </span>
                )
              ))
            )}
          </div>
        );
      })}
      {isBotTyping && <Skeleton className="message bot"><span className="typing-animation"></span></Skeleton>}
    </div>

    {/* Chat Input */}
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {showLogo && (
        <Center className="center-logo" p={'10px'} pb={0} flexDirection="column">
          <img style={{ height: '80px', marginTop: "18%", animation: 'upDown 1s infinite alternate' }} alt="Logo" />
          <Heading fontWeight="bold" fontSize={20}>How can I help you today?</Heading>
          <br />
          <br />
          <br />
          {!isMobile && (
            <Grid templateColumns="repeat(2, 1fr)" gap={3}>
              <Box>
                <Card height="100%" cursor={'pointer'} borderColor="black" onClick={() => setSelectedChat(true)}>
                  <CardBody style={{ borderColor: 'rgba(0,0,0,.15)', borderRadius: 'inherit', borderWidth: 'thin', padding: '20px' }}>
                    <Button>Chat.</Button>
                  </CardBody>
                </Card>
              </Box>
              <Box>
                <Card height="100%" cursor={'pointer'} borderColor="black" onClick={() => setSelectedVideo(true)}>
                  <CardBody style={{ borderColor: 'rgba(0,0,0,.15)', borderRadius: 'inherit', borderWidth: 'thin', padding: '20px' }}>
                    <Button>Search from video.</Button>
                  </CardBody>
                </Card>
              </Box>
              <Box>
                <Card height="100%" cursor={'pointer'} borderColor="black" onClick={() => setSelectedPrompt(true)}>
                  <CardBody style={{ borderColor: 'rgba(0,0,0,.15)', borderRadius: 'inherit', borderWidth: 'thin', padding: '20px' }}>
                    <Button>Search with Prompt.</Button>
                  </CardBody>
                </Card>
              </Box>
              <Box>
                <Card height="100%" cursor={'pointer'} borderColor="black" onClick={() => handleCardSubmit()}>
                  <CardBody style={{ borderColor: 'rgba(0,0,0,.15)', borderRadius: 'inherit', borderWidth: 'thin', padding: '20px' }}>
                    <Button>Alert</Button>&nbsp;
                    <Button onClick={() => handleDashboardAlertClick(input)}>Dashboard-Alert</Button>
                  </CardBody>
                </Card>
              </Box>
            </Grid>
          )}
        </Center>
      )}

      <form onSubmit={handleMessageSubmit} className="chatbot-input-form" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <input
          type="text"
          placeholder="Ask me anything..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          style={{ flex: 1, marginRight: '10px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px', width: '100%' }}
        />
        <div>
          <input type="file" accept="image/*" onChange={handleFileChange} />
        </div>
        <button type="submit" style={{ padding: '10px', borderRadius: '5px', backgroundColor: '#4b93bf', color: '#fff', border: 'none', fontSize: '16px', cursor: 'pointer' }}>Send</button>
      </form>
    </Box>
  </div>
</div>


  );
};

export default ArcisGPT;

