import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button,
  VStack,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
} from '@chakra-ui/react';

import axios from 'axios';


const FTPSetup = ({deviceId}) => {
   
  return (

    <Flex direction="column" padding="10px" gap={4}>
    <Box>
      <Text fontWeight="bold" fontSize="lg" color="blue.500">
        FTP Setup
      </Text>
    </Box>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          FTP Switch
        </FormLabel>
        <Checkbox />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          FTP Synchronization Switch
        </FormLabel>
        <Checkbox />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0">
          FTP Synchronization Type
        </FormLabel>
        <Select width="auto" placeholder="Select Gateway">
          <option value="Sync all recordings by time(days)">Sync all recordings by time(days)</option>
          <option value="Sync all recordings">Sync all recordings</option>
        </Select>
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP Custom Synchronization Time</FormLabel>
          <Input value="" width="auto" />
        </FormControl>
      </Flex>
      <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          FTP Schedule Switch
        </FormLabel>
        <Checkbox />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP Port</FormLabel>
          <Input value="" width="auto" />
        </FormControl>
      </Flex>
      <Flex direction="row" align="center" gap={4}>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP user name</FormLabel>
          <Input value="" width="auto" />
        </FormControl>
      </Flex>
      <Flex direction="row" align="center" gap={4}>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP Password</FormLabel>
          <Input value="" width="auto" />
        </FormControl>
      </Flex>
      <Flex direction="row" align="center" gap={4}>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP Server IP(domain name)</FormLabel>
          <Input value="" width="auto" />
        </FormControl>
      </Flex>
      <Flex direction="row" align="center" gap={4}>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP upload directory</FormLabel>
          <Input value="" width="auto" />
        </FormControl>
      </Flex>
      <Flex direction="row" align="center" gap={4}>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP upload video path</FormLabel>
          <Input value="" width="auto" />
        </FormControl>
      </Flex>
      <Flex direction="row" align="center" gap={4}>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP upload video path</FormLabel>
          <Input value="" width="auto" />
        </FormControl>
      </Flex>
      <Flex direction="row" align="center" gap={4}>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP upload picture path</FormLabel>
          <Input value="" width="auto" />
        </FormControl>
      </Flex>
      <Flex direction="row" align="center" gap={4}>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP upload time interval(in seconds)</FormLabel>
          <Input value="" width="auto" />
        </FormControl>
      </Flex>
      <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP Upload Way</FormLabel>
          <Select width="auto" placeholder="Select FTP Upload Way">
            <option value="Alarm">Alarm</option>
            <option value="Time">Time</option>
          </Select>
          </FormControl>
          </Flex>
                <Flex direction="row" align="center" gap={4}>
          <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0">FTP Upload Content</FormLabel>
          <Select width="auto" placeholder="Select FTP Upload Way">
            <option value="Upload Picture">Upload Picture</option>
            <option value="Upload Video">Upload Video</option>
            <option value="Upload Picture and Video">Picture and Video</option>
          </Select>
          </FormControl>
          </Flex>
          <Flex gap={1}>
              <Box mt={4} >
            <Button colorScheme="blue">Test</Button>
          </Box>
              <Box mt={4}>
            <Button colorScheme="blue">Save</Button>
          </Box>
          </Flex>
            </Flex>
);
};

export default FTPSetup;