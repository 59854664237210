import React, { useEffect, useState } from 'react';
import styles from './EventFilter.module.css';
import { getEventList_AI } from '../../services/aiCamera';

const EventFilter = ({ model, onModelChange, onEventsUpdate }) => {
  const [selectedModel, setSelectedModel] = useState('crowd');
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch data for the default model "crowd" when the component mounts
    const fetchDefaultData = async () => {
      await handleModelChange({ target: { value: 'crowd' } });
    };

    fetchDefaultData();
  }, []);

  const handleModelChange = async (event) => {
    const modelname = event.target.value;
    setSelectedModel(modelname);
    onModelChange(modelname);

    // Call the API with the selected model
    try {
      const pageNumber = 200; // Example page number, change as needed
      const itemsPerPage = 10; // Example items per page, change as needed

      const response = await getEventList_AI(modelname, pageNumber, itemsPerPage);
      // const eventData = response;
      console.log(response)
      setEvents(response);
      onEventsUpdate(response); // Pass the events to the parent component
    } catch (error) {
      console.error('Error fetching event data:', error);
    }
  };

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.deviceFilter}>
        <label htmlFor="deviceSelect" className={styles.visuallyHidden}>Select Device</label>
      </div>
      <div className={styles.modelFilter}>
        <label htmlFor="modelSelect" className={styles.visuallyHidden}>Select Detection Model</label>
        <select
          id="modelSelect"
          className={styles.select}
          value={selectedModel} // Set the selected value to selectedModel
          onChange={handleModelChange}
        >
          <option value="">All detection models</option>
          {model.map((modelItem, index) => (
            <option key={index} value={modelItem.replace(".pt", "")}>
              {modelItem.replace(".pt", "")}
            </option>
          ))}
        </select>
        <button className={styles.clearButton}>clear</button>
      </div>
    </div>
  );
};

export default EventFilter;
