import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button
} from '@chakra-ui/react';
import CanvasModal from './LineCrossCanvas';
import axios from 'axios';
import LineCrossCanvas from './LineCrossCanvas';

const LineCrossingDetection = ({deviceId}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState(false);
  const [whiteLightAlarm, setWhiteLightAlarm] = useState(false);
  const [appMessagePush, setAppMessagePush] = useState(false);
  const [rtmpPush, setRtmpPush] = useState(false);
  const [ftpPush, setFtpPush] = useState(false);
  const [direction, setDirection] = useState('');
  const [error, setError] = useState(null);
  const [detectLine, setDetectLine] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/getlinecross',{
          params: {
            deviceId: deviceId
          }});
         
          setIsEnabled(response.data.Enabled)
          setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled)
          setFtpPush(response.data.AlarmOut.FtpPush.Enabled)
          setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled)
          setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled)
          setAppMessagePush(response.data.AlarmOut.AppPush.Enabled)
          setDirection(response.data.Direction)
          // console.log(response.data.Direction)
          setDetectLine(response.data.DetectLine);
   
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]); 

  const handleSave = async () => {
    try {
      const response = await axios.put(`https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/setlinecross`, {
        Enabled: isEnabled,
        DetectLine: detectLine,
        DetectObj: 'Human',
        Direction: direction,
        AlarmOut: {
          AudioAlert: {
            Enabled: alarmSound,
          },
          LightAlert: {
            Enabled: whiteLightAlarm,
          },
          AppPush: {
            Enabled: appMessagePush,
          },
          RtmpPush: {
            Enabled: rtmpPush,
          },
          FtpPush: {
            Enabled: ftpPush,
          },
        }
      }, {
        params: { deviceId: deviceId }
      });

      console.log('Settings saved', response.data);
    } catch (error) {
      console.error('Error saving settings', error);
    }
  };


  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };

  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };
  
  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  const handleCanvasData = (line, direction) => {
    setDetectLine(line);
    setDirection(direction);
  };



  return (
    <Flex direction="column" padding="10px" gap={4}>
      <Box>
        <Text fontWeight="bold" fontSize="lg" color="blue.500">
          Line_Crossing_Detection
        </Text>
      </Box>
      <Flex>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0" mr={4}>
            Enable
          </FormLabel>
          <Checkbox isChecked={isEnabled} onChange={(e) => setIsEnabled(e.target.checked)} />
        </FormControl>
      </Flex>
      <Flex>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0" mr={4}>
            Enable_alarn_sound
          </FormLabel>
          <Checkbox isChecked={alarmSound} onChange={(e) => setAlarmSound(e.target.checked)} />
        </FormControl>
      </Flex>
      <Flex>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0" mr={4}>
            Enable_white_light_alarm
          </FormLabel>
          <Checkbox isChecked={whiteLightAlarm} onChange={(e) => setWhiteLightAlarm(e.target.checked)} />
        </FormControl>
      </Flex>
      <Flex>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0" mr={4}>
            Enable_App_message_push
          </FormLabel>
          <Checkbox isChecked={appMessagePush} onChange={(e) => setAppMessagePush(e.target.checked)} />
        </FormControl>
      </Flex>
      <Flex>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0" mr={4}>
            Enable_Rtmp_push
          </FormLabel>
          <Checkbox isChecked={rtmpPush} onChange={(e) => setRtmpPush(e.target.checked)} />
        </FormControl>
      </Flex>
      <Flex>
        <FormControl display="flex" alignItems="center">
          <FormLabel marginBottom="0" mr={4}>
            Enable_Ftp_push
          </FormLabel>
          <Checkbox isChecked={ftpPush} onChange={(e) => setFtpPush(e.target.checked)} />
        </FormControl>
      </Flex>
      
      <Box mt={4}>
        <Button colorScheme="blue" onClick={handleSave}>
          SaveLineCrossingDetectionSettings
        </Button>
      </Box>
      
      <Box mt={4}>
        <Button colorScheme="teal" onClick={openCanvasModal}>
          Open Canvas
        </Button>
      </Box>
      <LineCrossCanvas isOpen={isCanvasModalOpen}  onClose={closeCanvasModal} onCanvasData={handleCanvasData} 
      existingCoordinates={detectLine}
      existingDirection={direction}
      deviceId={deviceId}
      />
    </Flex> 
  );
};

export default LineCrossingDetection;
