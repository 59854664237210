// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar__JpSEe {
    width: 200px;
    padding-top: 104px;
    margin-left: 40px;
    margin-top: -80px;
  }
  
  .Sidebar_menuList__gZp-1 {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .Sidebar_menuItem__lqepw {
    display: flex;
    align-items: center;
    gap: 11px;
    margin-bottom: 28px;
    cursor: pointer;
  }
  
  .Sidebar_menuIcon__k1FsW {
    width: 24px;
    height: 24px;
  }
  
  @media (max-width: 991px) {
    .Sidebar_sidebar__JpSEe {
      display: none;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/AIEvents/Sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE;MACE,aAAa;IACf;EACF","sourcesContent":[".sidebar {\r\n    width: 200px;\r\n    padding-top: 104px;\r\n    margin-left: 40px;\r\n    margin-top: -80px;\r\n  }\r\n  \r\n  .menuList {\r\n    list-style-type: none;\r\n    padding: 0;\r\n    margin: 0;\r\n  }\r\n  \r\n  .menuItem {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 11px;\r\n    margin-bottom: 28px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .menuIcon {\r\n    width: 24px;\r\n    height: 24px;\r\n  }\r\n  \r\n  @media (max-width: 991px) {\r\n    .sidebar {\r\n      display: none;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar_sidebar__JpSEe`,
	"menuList": `Sidebar_menuList__gZp-1`,
	"menuItem": `Sidebar_menuItem__lqepw`,
	"menuIcon": `Sidebar_menuIcon__k1FsW`
};
export default ___CSS_LOADER_EXPORT___;
