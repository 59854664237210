import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  
  Box, IconButton,
  Select,
} from '@chakra-ui/react';

import { useToast } from '@chakra-ui/react';
import { FiArrowDown, FiArrowLeft, FiArrowRight, FiArrowUp, FiZoomIn, FiZoomOut } from 'react-icons/fi';


const CameraPTZ = ({deviceId}) => {
// const deviceid = deviceId;
  const toast = useToast();
  
  const [isMobile, setIsMobile] = useState(false);
  const [selectedSpeed, setselectedSpeed] = useState('');
  // console.log(selectedSpeed)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const showToast  = (msg)=>{
    toast({
      description:msg,
      status:'success',
      duration: 3000,
      position:'bottom-left',
      isClosable:false
    })
  }
  const handleJoystickClick = async (direction) => {
    const username = 'admin';
    const password = ''; // Ensure you replace this with the actual password
    // const selectedSpeed = 6; // Example speed value
    const moveDuration = 1000; // Duration to move the camera in milliseconds (1 second in this example)
  
    const step = 0; // Adjust as needed
    const presetNUM = 1; // Adjust as needed
  
    let act;
    switch (direction) {
      case 'left':
        act = 'left';
        break;
      case 'right':
        act = 'right';
        break;
      case 'up':
        act = 'up';
        break;
      case 'down':
        act = 'down';
        break;
      default:
        console.error('Invalid direction');
        return;
    }
  
    const ptzParams = {
      '-step': step,
      '-act': act,
      '-speed': selectedSpeed,
      '-presetNUM': presetNUM,
      deviceId: `${deviceId}.torqueverse.dev` // Replace with actual deviceId
    };
  
    const authHeader = 'Basic ' + btoa(`${username}:${password}`);
  
    try {
      // Send the initial request to move the camera
      const response = await axios.post('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/ptz', ptzParams, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authHeader
        }
      });
      console.log('PTZ Status:', response.data);
  
      // Wait for the specified duration before sending the stop command
      await new Promise(resolve => setTimeout(resolve, moveDuration));
  
      // Send the stop command
      const stopParams = {
        '-step': step,
        '-act': 'stop',
        '-speed': selectedSpeed,
        '-presetNUM': presetNUM,
        deviceId: `${deviceId}.torqueverse.dev` // Replace with actual deviceId
      };
  
      const stopResponse = await axios.post('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/ptz', stopParams, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authHeader
        }
      });
      console.log('Stop PTZ Status:', stopResponse.data);
  
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };


   

  return (
  <>
<Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      position="absolute"
      top="50%"
      left="5%"
      transform="translate(-50%, -50%)"
      zIndex={1}
    >
      <IconButton
      size={isMobile ? 'xs%' : 'sm'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        ml={6}
        width="30px"
        height="30px"
        borderRadius="50%"
        border="2px solid rgba(29, 30, 34, 0.7)"
        backgroundColor="rgba(29,30,34,.7)"
        color={"white"}
        variant="ghost"
        cursor="pointer"
        
        icon={<FiArrowUp />}
        onClick={() => handleJoystickClick("up")}
      >

      </IconButton>


      <Box display="flex" justifyContent="space-between" >

        <IconButton
        size={isMobile ? 'xs%' : 'sm'}
         marginRight="10px"
         width="30px"
         height="30px"
         borderRadius="50%"
         border="2px solid rgba(29, 30, 34, 0.7)"
         backgroundColor="rgba(29,30,34,.7)"
         color={"white"}
         variant="ghost"
          
         icon={<FiArrowLeft />}
          cursor="pointer"
       
          onClick={() => handleJoystickClick("left")}
        >
          {/* <FiArrowLeft size={25} color="white" /> */}
        </IconButton>
        <IconButton
        size={isMobile ? 'xs%' : 'sm'}
        marginLeft="10px"
        width="30px"
        height="30px"
        borderRadius="50%"
        border="2px solid rgba(29, 30, 34, 0.7)"
        backgroundColor="rgba(29,30,34,.7)"
        color={"white"}
        variant="ghost"
          cursor="pointer"
          icon={<FiArrowRight />}
          onClick={() => handleJoystickClick("right")}
        >
          {/* <FiArrowRight size={25} color="white" /> */}
        </IconButton>
      </Box>


      <IconButton
      size={isMobile ? 'xs%' : 'sm'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        ml={6}
        width="30px"
        height="30px"
        borderRadius="50%"
        border="2px solid rgba(29, 30, 34, 0.7)"
        backgroundColor="rgba(29,30,34,.7)"
        color={"white"}
        variant="ghost"
        cursor="pointer"
        icon={<FiArrowDown />}
        onClick={() => handleJoystickClick("down")}
      >
      </IconButton>
     

      
        </Box>

        <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      position="absolute"
      top="65%"
      left="7%"
      transform="translate(-50%, -50%)"
      zIndex={1}
    >
 <Select
        mt={4}
        // ml={6}
        width="100px"
        backgroundColor="rgba(29,30,34,.7)"
        color={"white"}
        border="2px solid rgba(29, 30, 34, 0.7)"
        onChange={(e) => setselectedSpeed(e.target.value)}
      >
        <option value="1" style={{ backgroundColor: "#1d1e22", color: "white" }}>Speed 1</option>
        <option value="2" style={{ backgroundColor: "#1d1e22", color: "white" }}>Speed 2</option>
        <option value="3" style={{ backgroundColor: "#1d1e22", color: "white" }}>Speed 3</option>
        <option value="4" style={{ backgroundColor: "#1d1e22", color: "white" }}>Speed 4</option>
        <option value="5" style={{ backgroundColor: "#1d1e22", color: "white" }}>Speed 5</option>
        <option value="6" style={{ backgroundColor: "#1d1e22", color: "white" }}>Speed 6</option>
      </Select>
    </Box>
       
        {/* <Box
      display="flex"
      flexDirection={isMobile ? 'row' : 'column'}
      justifyContent="flex-end"
      position="absolute"
      top={isMobile ? '85%' : "50%"}
      left={isMobile ? '15%' : "3%"}
      
      transform="translate(-50%, -50%)"
      zIndex={1}
    >
        <IconButton
        width="30px"
        height="30px"
        size={isMobile ? 'xs%' : 'sm'}
          // size="sm"
          marginRight={2}
          borderTopLeftRadius={"50%"}
          borderTopRightRadius={"50%"}
          borderBottomLeftRadius={"0%"}
          borderBottomRightRadius={"0%"}
  //         onClick={() => handleInputChange({
  //  })}
          backgroundColor="rgba(29,30,34,.7)"
          color={"white"}
          
          aria-label="Zoom In"
          variant="ghost"
          marginBottom={0.5}
          icon={<FiZoomIn />}
          _hover={{ backgroundColor: 'rgba(29, 30, 34, 1)' }}
        />
         <IconButton
          width="30px"
          height="30px"
         size={isMobile ? 'xs%' : 'sm'}
         borderTopLeftRadius={"0%"}
         borderTopRightRadius={"0%"}
         borderBottomLeftRadius={"50%"}
         borderBottomRightRadius={"50%"}
          // size="sm"
          marginBottom={0.5}
          marginRight={2}
          // onClick={() => handleInputChange({})}
          backgroundColor="rgba(29,30,34,.7)"
          color={"white"}
          variant="ghost"
          aria-label="Zoom Out"
          icon={<FiZoomOut />}
          _hover={{ backgroundColor: 'rgba(29, 30, 34, 1)' }}
        />
    </Box> */}
      
  </>
  );
};

export default CameraPTZ;
