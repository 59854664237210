import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Sidebar.module.css';

const menuItems = [
  { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/2e2227fd6d47f5cad4007deb4cb97076821124bd0ac8174f92aaa53f2dfbf1aa?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1', label: 'Cameras', path: '/Dashboard' },
  { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/c577ab100840c57c542a157f05edc263ee5b0a950f3c658e4243a6f4f8d4fbdb?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1', label: 'Multi Screen', path: '/multiscreen' },
  { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/8ff41b3037bba445c471e6de7fb04ed12b8dbed7352406fcee3708d5dd06caf8?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1', label: 'Events', path: '/AIEvents' },
  { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/03e51ca0fca47c0126aadaa6945fb60aafc700b00e6360e974d6035a82ccfcbc?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1', label: 'Profile', path: '/profileinfo' },
  { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/f64794a41be55f9945827f3c5e01dc35811dcc4920e9e8d28dc365c01b23c173?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1', label: 'Subscription', path: '/subscription' },
  { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/7aa834b209178650007785d19eca4f980c07a00c1196b49f12f2195efc597523?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1', label: 'Settings', path: '/settingpage' },
  { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/51282e1907d3d1d5509c08253ab0ad67355d4cd420127453dca159bc80960c0f?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1', label: 'Open Map', path: '/geomap' },
  { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/628c155241260abfa0b074428a1030f0ca09ef0e1be07ddb032e38ca5c0f067b?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1', label: 'Logout', path: '/' },
];

function Sidebar() {
  const navigate = useNavigate();

  const handleclick = (path) => {
    navigate(path);
  };

  return (
    <nav className={styles.sidebar}>
      <ul className={styles.menuList}>
        {menuItems.map((item, index) => (
          <li key={index} className={styles.menuItem} onClick={() => handleclick(item.path)}>
            <img src={item.icon} alt={item.label} className={styles.menuIcon} />
            <span>{item.label}</span>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Sidebar;
