import PropTypes from 'prop-types';
import { Box, Flex, Text, Icon } from '@chakra-ui/react';

export const OverviewBudget = (props) => {
  const { difference, positive = false, sx, value, title, sanand, icon: IconComponent, color, iconColor, bcolor } = props;

  return (
    <Box sx={sx} bg="white" p={4} borderRadius="md" boxShadow="md">
      <Flex justify="space-between" align="center">
        <Box>
          <Text fontFamily="'Plus Jakarta Sans', sans-serif" color="gray.500" fontSize="xs" fontWeight="bold" textTransform="uppercase">
            {title}
          </Text>
          <Text fontFamily="'Plus Jakarta Sans', sans-serif" fontSize="3xl" fontWeight="bold">
            {value}
          </Text>
          <Text fontSize="sm">{sanand}</Text>
        </Box>
        <Box
          bg={color || 'red.500'}
          size="lg"
          borderColor={bcolor || 'white'}
          borderWidth={2}
          borderRadius={'50%'}
          width={10}
          height={10}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {IconComponent && (
            <Icon as={IconComponent} color={'inherit'} w={6} h={6} />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

OverviewBudget.propTypes = {
  difference: PropTypes.number,
  positive: PropTypes.bool,
  sx: PropTypes.object,
  value: PropTypes.string.isRequired,
  sanand: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  bcolor: PropTypes.string,
};
