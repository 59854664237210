import React from 'react';
import DeviceList from './DeviceList';
import styles from './Dashboard.module.css';
import SessionTimeout from '../../components/SessionTimeout';

function Dashboard() {
  return (
    <div className={styles.dashboardContainer}>
    
      <main className={styles.mainContent}>
      <SessionTimeout timeoutDuration={1800000} />
        <DeviceList />
      </main>
    </div>
  );
}

export default Dashboard;