// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EventList_eventList__2TuRM {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 50px;
    margin-top: 30px;
  }
  
  .EventList_eventItem__VPoOL {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .EventList_deviceName__Cgwlr {
    width: 100px;
    font-weight: 600;
  }
  
  .EventList_eventDetails__fzkq1 {
    display: flex;
    align-items: center;
    gap: 17px;
    flex: 1 1;
  }
  
  .EventList_eventIcon__4\\+HI0 {
    aspect-ratio: 1;
    width: 42px;
  }
  
  .EventList_eventType__X3ZET {
    font-size: 16px;
  }
  
  .EventList_eventTime__T18UO {
    font-size: 14px;
    color: #8b8b8b;
  }
  
  @media (max-width: 991px) {
    .EventList_eventItem__VPoOL {
      flex-direction: column;
      align-items: flex-start;
      margin-left: -40px;
    }
  
    .EventList_eventTime__T18UO {
      align-self: flex-end;
      margin-top: -50px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Notification/EventList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,SAAO;EACT;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,cAAc;EAChB;;EAEA;IACE;MACE,sBAAsB;MACtB,uBAAuB;MACvB,kBAAkB;IACpB;;IAEA;MACE,oBAAoB;MACpB,iBAAiB;IACnB;EACF","sourcesContent":[".eventList {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 20px;\r\n    margin-left: 50px;\r\n    margin-top: 30px;\r\n  }\r\n  \r\n  .eventItem {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 20px;\r\n  }\r\n  \r\n  .deviceName {\r\n    width: 100px;\r\n    font-weight: 600;\r\n  }\r\n  \r\n  .eventDetails {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 17px;\r\n    flex: 1;\r\n  }\r\n  \r\n  .eventIcon {\r\n    aspect-ratio: 1;\r\n    width: 42px;\r\n  }\r\n  \r\n  .eventType {\r\n    font-size: 16px;\r\n  }\r\n  \r\n  .eventTime {\r\n    font-size: 14px;\r\n    color: #8b8b8b;\r\n  }\r\n  \r\n  @media (max-width: 991px) {\r\n    .eventItem {\r\n      flex-direction: column;\r\n      align-items: flex-start;\r\n      margin-left: -40px;\r\n    }\r\n  \r\n    .eventTime {\r\n      align-self: flex-end;\r\n      margin-top: -50px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventList": `EventList_eventList__2TuRM`,
	"eventItem": `EventList_eventItem__VPoOL`,
	"deviceName": `EventList_deviceName__Cgwlr`,
	"eventDetails": `EventList_eventDetails__fzkq1`,
	"eventIcon": `EventList_eventIcon__4+HI0`,
	"eventType": `EventList_eventType__X3ZET`,
	"eventTime": `EventList_eventTime__T18UO`
};
export default ___CSS_LOADER_EXPORT___;
