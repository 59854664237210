import React, { useEffect, useRef } from "react";
import styles from './DeviceComponent.module.css';

import DeviceView from './DeviceView';
import Controls from './Controls';
import Timeline from "./Timeline";


function DeviceComponent({device,goBack,status}) {
    
  return (
    <>
      <div className={styles.dashboardContainer}>
      {/* <Header /> */}
      <main className={styles.mainContent}>
    
        {/* <Sidebar /> */}
        <section className={styles.deviceSection}>
          <DeviceView device={device} goBack={goBack} status={status}/>
          <Timeline />
          <Controls device={device}/>
        </section>
      </main>
    </div>
    
  </>
  );
}

export default DeviceComponent;


// {/* <section className={styles.container}>
    
//     <header className={styles.header}>
   
//       <div className={styles.deviceInfo}>
       
//       <h1 className={styles.deviceName}>
//          <svg onClick={goBack} style={{ cursor: 'pointer',marginTop:'5px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//           <path d="M3 19V5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
//           <path d="M13 6L7 12L13 18" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
//           <path d="M7 12H21" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
//         </svg>
        
//         {device.cameraname}</h1>
//       </div>
//       <form className={styles.datePickerWrapper}>
//         <div className={styles.datePickerInput}>
//           <div className={styles.datePickerInputInner}>
//             <div className={styles.datePickerInputField}>
//               <div className={styles.datePickerLeading} />
//               <div className={styles.datePickerContent}>
//                 <label htmlFor="datePicker" className={styles['visually-hidden']}>Pick a date range</label>
//                 <input
//                   id="datePicker"
//                   className={styles.datePickerPlaceholder}
//                   type="text"
//                   placeholder="pick a date range"
//                   aria-label="Pick a date range"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//         <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8b985a7dfbf05eda968dc127cb6c1652b11c5541fd1a0c893ad30ddb9b38678?placeholderIfAbsent=true&apiKey=6cabb953a5b841639821dfc24d9cae92" className={styles.icon} alt="" />
//         <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9819f31e80fd0750c673e0721d29706aad946092a4c538a50730be5f79aa9b0a?placeholderIfAbsent=true&apiKey=6cabb953a5b841639821dfc24d9cae92" className={styles.icon} alt="" />
//       </form>
//     </header>
//     <div className={styles.deviceVisual}>
//     <div className={styles.playerContainer}>
//           <JessibucaPlayer
//             controls={true}
//             loadingText={'loading'}
//             src={`https://${device.deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`}
//             decoder="/decoder.js" 
//             style={{loading:"lazy", width: '100%', height: '100%',
//                zIndex: 1, position: 'relative'}}
//             objectFit={"contain"}
//           />
            
//         </div>
//       {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e2fa595bb5d525dfb7bf4a7d311d33d59a8f97782b83c36a801c92a1ecd58ec4?placeholderIfAbsent=true&apiKey=6cabb953a5b841639821dfc24d9cae92" className={styles.deviceImage} alt="Device visual representation" /> */}
//       {/* <img loading="lazy" src="https://www.figma.com/design/vuFLXmLM0KuuhKEpvGIyET/Arcis-AI-UI%2FUX-Design?node-id=316-7520&m=dev" className={styles.centerIcon} alt="" /> */}
//       <div className={styles.statusBadge}>connected</div>
      

//     </div>
//     <MediaControls/>
//     {/* <div className={styles.highlightBox} role="presentation" /> */}
    
//   </section> */}
