import axios from 'axios';
import { parseStringPromise } from 'xml2js';

// const baseURL = 'https://adiance-portal-backend-7d9tj.ondigitalocean.app'; // Set your API base URL here 
const baseURL = 'https://iccc.ambicam.com:4000/'; // Set your API base URL here 

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

instance.interceptors.response.use(
  response => {
    // If the response is successful, just return the response
    return response;
  },
  error => {
    // If the response has a status code of 401, redirect to the login page
    if (error.response && error.response.status === 401) {
      window.location.href = '/'; // Replace with your login route
    }
    // Otherwise, reject the promise with the error object
    return Promise.reject(error);
  }
);

function getUrlParameters() {
  const urlParams = new URLSearchParams(window.location.search);
  const shareId = urlParams.get('shareId');
  const contentId = urlParams.get('contentId');
  return { shareId, contentId };
}

export async function getCustomerCameraList(customerId,page,resultPerPage) {
  const gg = getUrlParameters();
  try {
    // const token = localStorage.getItem('token');
    const response = await instance.get('/camera', {
      params: {
        customerid: customerId,
        page: page,
        resultPerPage:resultPerPage,
        shareId : gg.shareId,
        contentId :gg.contentId
      },

    });
    return response.data;

  } catch (error) {
    throw error;
  }
}
