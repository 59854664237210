import axios from 'axios';
import { parseStringPromise } from 'xml2js';

const baseURL = 'https://adiance-portal-backend-7d9tj.ondigitalocean.app'; // Set your API base URL here 

const instance = axios.create({
  baseURL: baseURL
});

export async function deleteCamera(cameraId,deviceId) {
  try {
    const token = localStorage.getItem('token');
    const response = await instance.delete(`/camera/deletecamera`, {
      params:{
        id:cameraId,
        deviceid:deviceId
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },

    });
   
    return response.data;

  } catch (error) {
    throw error;
  }
}