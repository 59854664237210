import React, { useState, useRef } from 'react';
import styles from './Dashboard.module.css';
import { SlCalender } from "react-icons/sl";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import datepicker styles
import JessibucaPlayer from 'react-jessibuca';
import Psettings from '../../../components/Psettings';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import SDCardDataTable from '../../../components/SDCardDataTable'
function DeviceView({device,goBack}) {
  console.log(device) 
  let modifiedCameraUrl = device.cameraurl;
  if (device.cameraurl.startsWith('media12.ambicam.com') || device.cameraurl.startsWith('media5.ambicam.com')) {
    modifiedCameraUrl = device.cameraurl.replace(/media(12|5)\.ambicam\.com:\d+\/[a-z0-9]+\//, 'media5.ambicam.com:443/live/');
  }

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const datePickerRef = useRef(null);
  const [plantext,setPlantext] = useState('')
  const [deviceId,setDeviceId] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChange = (dates) => {
    const [start, end] = dates;
    setDateRange([start, end]);
  };

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
    if (calendarOpen) {
      datePickerRef.current.setOpen(false);
    } else {
      datePickerRef.current.setOpen(true);
    }
  };

  const handleCameraSettingsClick = (plantext,deviceId) => {

    setPlantext(plantext);
    setDeviceId(deviceId);
    onOpen();
  };

console.log(`https://${modifiedCameraUrl}${device.streamname}_${device.Aimodal.replace(".pt","")}.flv`)

  return (
    <>
    <section className={styles.deviceView}>
      <svg onClick={goBack} style={{ cursor: 'pointer',marginTop:'5px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M3 19V5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
         <path d="M13 6L7 12L13 18" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
         <path d="M7 12H21" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
       </svg>
      <header className={styles.deviceHeader}>
        <h2 className={styles.deviceTitle}>{device.cameraname}</h2>
        <ar/>
        {/* <time className={styles.deviceTime}>2024-07-11 23:30:02</time> */}
        <div className={styles.dateRangePicker}>
        <div className={styles.datePickerWrapper}>
            <DatePicker
              selected={dateRange[0]}
              onChange={handleChange}
              selectsRange
              placeholderText="Pick a date range"
              className={styles.dateInput}
              ref={datePickerRef}
              open={calendarOpen} // Control the open state
              onClickOutside={() => setCalendarOpen(false)} // Close when clicking outside
            />
            <SlCalender className={styles.calender} onClick={toggleCalendar} />
          </div>
          <button aria-label="Calendar" className={styles.iconButton}>
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8b985a7dfbf05eda968dc127cb6c1652b11c5541fd1a0c893ad30ddb9b38678?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Calendar" className={styles.icon} />
          </button>
          <button aria-label="Options" className={styles.iconButton}  onClick={() => handleCameraSettingsClick(plantext,deviceId)}>
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9819f31e80fd0750c673e0721d29706aad946092a4c538a50730be5f79aa9b0a?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Options" className={styles.icon} />
          </button>
        </div>
      </header>

      <div className={styles.deviceComponent}>
      <JessibucaPlayer
            className={styles.deviceImage}
            controls={true}
            loadingText={'loading'}
            // modifiedCameraUrl = cameraurl.replace(':1938', ':443');
            // src={`https://${device.deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`}
            src={`https://${modifiedCameraUrl}${device.streamname}_${device.Aimodal.replace(".pt","")}.flv`}
            decoder="/decoder.js" 
          />

        <div className={`${styles.deviceStatus} ${styles[device.islive === 1 ? 'connected' : 'disconnected']}`}>{device.islive === 1 ? 'connected' : 'disconnected'}</div>
    
      </div>
    </section>

    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
    <ModalOverlay />
    <ModalContent >
      <ModalHeader>Camera Settings</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {/* Put your camera settings UI here */} 
        <Psettings onClose={onClose} plantext={plantext} deviceid={deviceId}  />
        {/* <CameraSettings onClose={onClose} plantext={plantext} deviceid={deviceId}  /> */}
      </ModalBody>
      
    </ModalContent>
    </Modal>


</>
  );
}

export default DeviceView;
