import React from 'react';
import styles from './EventList.module.css';

const eventItems = [
  { device: "Device 2", type: "Human Detection", time: "11:33 33 AM", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb3334f32f3a790dd0126159508023ffad8be0e4f36551b38da5a21e40ad8849?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" },
  { device: "Device 2", type: "Human Detection", time: "11:02 30 AM", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/cb3334f32f3a790dd0126159508023ffad8be0e4f36551b38da5a21e40ad8849?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" },
  { device: "Device 2", type: "Face Detection", time: "11:02 30 AM", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/61443d1cbd8fc61949de247dd318c5dee8b1332eef9e06d48f5457e8245ed254?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" }
];

const EventList = ({ data }) => {

  console.log("::",data)


  return (
    <section className={styles.eventList}>
    {data.map((item, index) => (
      <div key={index} className={styles.eventItem}>
        {/* Serial Number */}
        <div className={styles.serialNumber}>{index + 1}.</div>

        {/* Truncated Camera ID */}
        <div className={styles.deviceName}>
          {item.cameraid.length > 10 ? item.cameraid.slice(0, 10) + '...' : item.cameraid}
        </div>

        {/* Model Name */}
        <div className={styles.eventDetails}>
          <span className={styles.eventType}>{item.modelname}</span>
        </div>

        {/* Send Time */}
        <div className={styles.eventTime}>{item.sendtime}</div>
      </div>
    ))}
  </section>
  );
};

export default EventList;