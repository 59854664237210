import React, { useEffect, useState } from 'react';
import { Select, FormControl, FormLabel, Checkbox, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { getCustomerCameraList } from '../../services/getcamera'; // Adjust the import path as needed
import { getAiModels, addAiCamera } from '../../services/aiCamera';

const AddAIDeviceModal = ({ isOpen, onClose, onSave }) => {
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [device, setDevice] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [eventNotifications, setEventNotifications] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedDevice, setSelectedDevice] = useState(null);

  useEffect(() => {
    if (isOpen) {
      const fetchDevicesAndModels = async () => {
        // const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const customerId = localStorage.getItem('userId');
        const resultPerPage = 1000; // Adjust as needed

        try {
          // Fetch devices
          const deviceResult = await getCustomerCameraList(customerId, 1, resultPerPage);
            if (deviceResult.success) {
                const deviceOptions = deviceResult.cameras.map(camera => ({
                value: camera.deviceid,
                label: camera.cameraname || camera.deviceid
                }));
                setDeviceOptions(deviceOptions);
                setDevice(deviceResult);
            }

          // Fetch models
          const modelResult = await getAiModels(); // Adjust if needed to pass any parameters
          setModelOptions(modelResult.models);
        

        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchDevicesAndModels();
    }
  }, [isOpen]);

  const handleDeviceSelect = (e) => {
    const deviceid = e.target.value;
    const deviceData = device.cameras.find(device => device.deviceid === deviceid);
    setSelectedDevice(deviceData);
  };

  const handleSave = async () => {
    if (!selectedDevice) {
      console.error('No device selected');
      return;
    }
  
    try {
        console.log(`${selectedModel}.pt`)
      // const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const customerid = localStorage.getItem('userId');
      const cameraid = selectedDevice.cameraid;
      const rtmp = `rtmp://${selectedDevice.cameraurl}${selectedDevice.streamname}`;
      const ai_name = `${selectedModel}.pt`; // Assume this is selected from another dropdown
      const event = eventNotifications; // Event notification state
  console.log(cameraid )

      const addAicamera = await addAiCamera(customerid, cameraid, ai_name, event, rtmp, selectedDevice.stream);
  
      if (addAicamera.success) {
        const newDevice = {
          ...selectedDevice,
          ai_name,
          eventNotifications,
          status: 'connected', // Example of adding additional fields
        };
  
        onSave(newDevice); // Pass the new device data to the parent component
        console.log('AI Request Sent Successfully:', addAicamera);
        onClose(); // Close the modal after saving
      }
    } catch (error) {
      console.error('Error adding AI to camera:', error);
    }
  };
  

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add AI Device</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="device">
            <FormLabel>Select Device</FormLabel>
            <Select placeholder="Select Device" onChange={handleDeviceSelect}>
              {deviceOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormLabel mt={4}>Select Model</FormLabel>
            <Select
              placeholder="Select Model"
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
            >
              {modelOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.replace(".pt", "")}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl mt={4} id="event-notifications">
            <Checkbox
              isChecked={eventNotifications}
              onChange={(e) => setEventNotifications(e.target.checked)}
            >
              Event Notifications
            </Checkbox>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave} isDisabled={loading}>
            Save
          </Button>
          <Button onClick={onClose} isDisabled={loading}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddAIDeviceModal;
