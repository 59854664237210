import { useEffect, useState } from 'react';
import withAuth from '../components/withAuth';
import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import { getCustomerCameraList } from '../services/getcamera';
import LiveFeed from '../components/LiveFeed-Hls';
import {
  Box, Badge,
  Text, SimpleGrid,
  GridItem,
  useColorModeValue,
  HStack, Spinner, Flex,
  Link
} from '@chakra-ui/react';
import OfflineMessage from '../components/OfflineMessage';
import ReactPaginate from 'react-paginate';
import PullToRefresh from 'react-simple-pull-to-refresh';

const CameraFeedsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [cameraList, setCameraList] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [selectedCameraIndex, setSelectedCameraIndex] = useState(0);

  const fetchData = async (page, updateOnlyStatus = false) => {
    // const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const customerId = localStorage.getItem('userId');
    let resultPerPage = 9;
    if (window.innerWidth > 1645) {
      resultPerPage = 12; // Set to 12 if the screen width is greater than 1645px
    }
    if (!updateOnlyStatus && page > 1) {
      setIsLoading(false);
    } else if (!updateOnlyStatus) {
      setIsLoading(true);
    }

    try {
      const statusResponse = await fetch('https://adiance-portal-backend-7d9tj.ondigitalocean.app/status');
      const statusData = await statusResponse.json();

      if (!updateOnlyStatus) {
        const result = await getCustomerCameraList(customerId, page, resultPerPage);
        if (result.success) {
          const updatedCameraList = result.cameras.map(camera => {
            const matchingStatus = statusData.proxies.find(proxy =>
              proxy.name.trim().toLowerCase() === camera.deviceid.trim().toLowerCase()
            );
            return {
              ...camera,
              status: matchingStatus ? matchingStatus.status : 'unknown'
            };
          });

          if (page > 1) {
            setCameraList((prevCameraList) => [...prevCameraList, ...updatedCameraList]);
          } else {
            setCameraList(updatedCameraList);
          }
          setIsLoading(false);
        } else {
          if (result.message === 'Invalid token or authorization') {
            // Handle logout here
          }
        }
      } else {
        setCameraList(prevCameraList => prevCameraList.map(camera => {
          const matchingStatus = statusData.proxies.find(proxy =>
            proxy.name.trim().toLowerCase() === camera.deviceid.trim().toLowerCase()
          );
          return {
            ...camera,
            status: matchingStatus ? matchingStatus.status : 'unknown'
          };
        }));
      }

      if (!updateOnlyStatus) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching camera list:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);

    const statusInterval = setInterval(() => {
      fetchData(1, true);
    }, 5000);

    return () => clearInterval(statusInterval);
  }, []);

  useEffect(() => {
    const now = new Date();
    const midnight = new Date(now).setHours(24, 0, 0, 0);
    const msUntilMidnight = midnight - now;

    const timerId = setTimeout(() => {
      fetchData();
    }, msUntilMidnight);

    return () => clearTimeout(timerId);
  }, []);

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleThumbnailGenerated = (thumbnailUrl, cameraId) => {
    const updatedCameraList = cameraList.map((camera) =>
      camera.cameraid === cameraId ? { ...camera, thumbnailUrl } : camera
    );

    setCameraList(updatedCameraList);
  };

  const simplifyString = (inputString) => {
    return inputString.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  };

  const onlineCameraList = cameraList.filter((camera) => camera.islive);

  const sortedCameraList = [...onlineCameraList].sort((a, b) => {
    if (a.islive && !b.islive) return -1;
    if (!a.islive && b.islive) return 1;
    return 0;
  });

  const gridColumnTemplate = {
    base: "1fr",
    sm: "repeat(2, 1fr)",
    md: "repeat(3, 1fr)",
    lg: "repeat(3,1fr)",
  };

  const [page, setPage] = useState(0);
  const itemsPerPage = 6;
  const pageCount = Math.ceil(sortedCameraList.length / itemsPerPage);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setPage(selectedPage);
  };

  const offset = page * itemsPerPage;
  const currentPageData = sortedCameraList.slice(offset, offset + itemsPerPage);

  const handleRefresh = () => {
    return new Promise((resolve, reject) => {
      fetchData();
      setTimeout(() => {
        resolve("Refresh completed successfully");
      }, 200);
    });
  };

  const getModifiedLiveUrl = (camera) => {
    let modifiedCameraUrl;
    let lastfilename;
    let cameraurl;

    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString().slice(-2);
    const formattedDate = `${day}_${month}_${year}`;

    if (camera.cameraurl.startsWith('media5')) {
      modifiedCameraUrl = camera.cameraurl.replace(':1938', ':443');
      lastfilename = `${formattedDate}/${camera.streamname}.m3u8`;
    } else if (camera.cameraurl.startsWith('media11.ambicam.com')) {
      modifiedCameraUrl = camera.cameraurl.replace(':1938', ':443');
      lastfilename = `${formattedDate}/${camera.streamname}.m3u8`;
    } else if (camera.cameraurl.startsWith('media1.ambicam.com')) {
      modifiedCameraUrl = camera.cameraurl.replace(':1938', ':443');
      lastfilename = `${formattedDate}/${camera.streamname}_live.m3u8`;
    } else if (camera.cameraurl.startsWith('media12.ambicam.com')) {
      modifiedCameraUrl = camera.cameraurl.replace('media12.ambicam.com:1938', 'media1.ambicam.com:443');
      lastfilename = `${formattedDate}/${camera.streamname}_live.m3u8`;
    } else if (camera.cameraurl.startsWith('media6.ambicam.com')) {
      modifiedCameraUrl = camera.cameraurl.replace('media6.ambicam.com:1938', 'media1.ambicam.com:443');
      lastfilename = `${formattedDate}/${camera.streamname}_live.m3u8`;
    } else {
      modifiedCameraUrl = camera.cameraurl.replace(':1938', ':443');
      lastfilename = `index.m3u8`;
    }

    return `https://${modifiedCameraUrl}${camera.streamname}/${lastfilename}`;
  };

  const [currentCameraIndex, setCurrentCameraIndex] = useState(0);

  const setMainCameraIndex = (index) => {
    if (index >= 0 && index < sortedCameraList.length) {
      setCurrentCameraIndex(index);
    }
  };

  const TransparentOverlay = ({ onClick }) => {
    return (
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        zIndex={10}
        onClick={onClick}
        backgroundColor="rgba(0, 0, 0, 0)"
        cursor="pointer"
      />
    );
  };

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      {isMobile && (
        <>
          <MobileHeader headerText="Multiple View" />
          <Box
            paddingTop={isMobile ? '5rem' : '0.8rem'}
            paddingX={3}
            marginLeft={isMobile ? '0rem' : '5rem'}
          >
            <PullToRefresh onRefresh={handleRefresh}>
              {isLoading ? (
                <Flex align="center" justify="center" height="100vh">
                  <Spinner size="xl" thickness="4px" color="blue.500" emptyColor="gray.200" />
                </Flex>
              ) : (
                <SimpleGrid columns={gridColumnTemplate} spacing={4}>
                  {currentPageData.map((camera, index) => (
                    <GridItem
                      key={camera.cameraid}
                      as={Link}
                      onClick={() => setMainCameraIndex(offset + index)}
                      cursor="pointer"
                    >
                      <Box
                        w="100%"
                        h="100%"
                        borderRadius="lg"
                        overflow="hidden"
                        position="relative"
                        boxShadow="md"
                        border="2px solid"
                        borderColor={camera.islive ? 'green.500' : 'red.500'}
                      >
                        <LiveFeed
                          key={camera.cameraid}
                          cameraUrl={getModifiedLiveUrl(camera)}
                          cameraId={camera.cameraid}
                          onThumbnailGenerated={(url) => handleThumbnailGenerated(url, camera.cameraid)}
                        />
                        {!isPlaying && (
                          <TransparentOverlay
                            onClick={() => {
                              setSelectedCameraIndex(index);
                              handlePlay();
                            }}
                          />
                        )}
                        <Box
                          position="absolute"
                          bottom={0}
                          left={0}
                          p={2}
                          bg="rgba(0, 0, 0, 0.7)"
                          color="white"
                          width="100%"
                        >
                          <Text fontSize="sm" fontWeight="bold">
                            {camera.cameraname}
                          </Text>
                          <Badge colorScheme={camera.status === 'online' ? 'green' : 'red'}>
                            {camera.status === 'online' ? 'Online' : 'Offline'}
                          </Badge>
                        </Box>
                      </Box>
                    </GridItem>
                  ))}
                </SimpleGrid>
              )}
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </PullToRefresh>
          </Box>
        </>
      )}
      {!isMobile && (
        <>
          <DesktopHeader headerText="Multiple View" />
          <Box
            paddingTop={isMobile ? '5rem' : '1.5rem'}
            paddingX={3}
            marginLeft={isMobile ? '0rem' : '5rem'}
          >
            <PullToRefresh onRefresh={handleRefresh}>
              {isLoading ? (
                <Flex align="center" justify="center" height="100vh">
                  <Spinner size="xl" thickness="4px" color="blue.500" emptyColor="gray.200" />
                </Flex>
              ) : (
                <SimpleGrid columns={gridColumnTemplate} spacing={4}>
                  {currentPageData.map((camera, index) => (
                    <GridItem
                      key={camera.cameraid}
                      as={Link}
                      onClick={() => setMainCameraIndex(offset + index)}
                      cursor="pointer"
                    >
                      <Box
                        w="100%"
                        h="100%"
                        borderRadius="lg"
                        overflow="hidden"
                        position="relative"
                        boxShadow="md"
                        border="2px solid"
                        borderColor={camera.islive ? 'green.500' : 'red.500'}
                      >
                        <LiveFeed
                          key={camera.cameraid}
                          cameraUrl={getModifiedLiveUrl(camera)}
                          cameraId={camera.cameraid}
                          onThumbnailGenerated={(url) => handleThumbnailGenerated(url, camera.cameraid)}
                        />
                        {!isPlaying && (
                          <TransparentOverlay
                            onClick={() => {
                              setSelectedCameraIndex(index);
                              handlePlay();
                            }}
                          />
                        )}
                        <Box
                          position="absolute"
                          bottom={0}
                          left={0}
                          p={2}
                          bg="rgba(0, 0, 0, 0.7)"
                          color="white"
                          width="100%"
                        >
                          <Text fontSize="sm" fontWeight="bold">
                            {camera.cameraname}
                          </Text>
                          <Badge colorScheme={camera.status === 'online' ? 'green' : 'red'}>
                            {camera.status === 'online' ? 'Online' : 'Offline'}
                          </Badge>
                        </Box>
                      </Box>
                    </GridItem>
                  ))}
                </SimpleGrid>
              )}
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </PullToRefresh>
          </Box>
        </>
      )}
    </Box>
  );
};

export default withAuth(CameraFeedsPage);
