// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sidebar.module.css */

.Sidebar_sidebar__Br-2i {
    width: 200px; /* Fixed width for the sidebar */
    height: 100vh; /* Full height of the viewport */
    position: fixed; /* Fix the sidebar to the left side */
    top: 72px;
    left: 0;
    /* background-color: #fff; */
    padding: 20px 40px;
   
    z-index: 1000; /* Ensure it stays above the content */
  }
  
  .Sidebar_menuList__rKycE {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .Sidebar_menuItem__8DfZn {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  /* .menuItem:hover {
    background-color: #e2e6ea;
  } */
  
  .Sidebar_menuIcon__Zgzcj {
    width: 24px;
    height: 24px;
    margin-right: 11px;
  }
  
  .Sidebar_menuLabel__SGLmU {
    vertical-align: middle;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/styles/Sidebar.module.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,YAAY,EAAE,gCAAgC;IAC9C,aAAa,EAAE,gCAAgC;IAC/C,eAAe,EAAE,qCAAqC;IACtD,SAAS;IACT,OAAO;IACP,4BAA4B;IAC5B,kBAAkB;;IAElB,aAAa,EAAE,sCAAsC;EACvD;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,iCAAiC;EACnC;;EAEA;;KAEG;;EAEH;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["/* Sidebar.module.css */\r\n\r\n.sidebar {\r\n    width: 200px; /* Fixed width for the sidebar */\r\n    height: 100vh; /* Full height of the viewport */\r\n    position: fixed; /* Fix the sidebar to the left side */\r\n    top: 72px;\r\n    left: 0;\r\n    /* background-color: #fff; */\r\n    padding: 20px 40px;\r\n   \r\n    z-index: 1000; /* Ensure it stays above the content */\r\n  }\r\n  \r\n  .menuList {\r\n    list-style-type: none;\r\n    padding: 0;\r\n    margin: 0;\r\n  }\r\n  \r\n  .menuItem {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-bottom: 28px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s;\r\n  }\r\n  \r\n  /* .menuItem:hover {\r\n    background-color: #e2e6ea;\r\n  } */\r\n  \r\n  .menuIcon {\r\n    width: 24px;\r\n    height: 24px;\r\n    margin-right: 11px;\r\n  }\r\n  \r\n  .menuLabel {\r\n    vertical-align: middle;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar_sidebar__Br-2i`,
	"menuList": `Sidebar_menuList__rKycE`,
	"menuItem": `Sidebar_menuItem__8DfZn`,
	"menuIcon": `Sidebar_menuIcon__Zgzcj`,
	"menuLabel": `Sidebar_menuLabel__SGLmU`
};
export default ___CSS_LOADER_EXPORT___;
