import { useEffect } from 'react';
import withAuth from '../components/withAuth';
import { Box, Text } from '@chakra-ui/react';
import styles from './AI-Dashboard/Dashboard.module.css';

const Geomap = () => {
  useEffect(() => {
    const initMap = () => {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 20.5937, lng: 78.9629 }, // Center of India
        zoom: 5,
        styles: [
          {
            "stylers": [
              { "saturation": -100 },
              { "lightness": 50 }
            ]
          }
        ]
      });

      // Define the purple marker icon
      const purpleMarkerIcon = {
        url: 'https://maps.google.com/mapfiles/ms/icons/purple-dot.png', // URL to your purple marker
        scaledSize: new window.google.maps.Size(40, 40) // Adjust size
      };

      // Add a static marker with the custom purple icon
      new window.google.maps.Marker({
        position: { lat: 20.5937, lng: 78.9629 }, // Marker position (Example: India)
        map,
        icon: purpleMarkerIcon
      });
    };

    if (window.google && window.google.maps) {
      initMap();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD2CF3PlGBd0tQhusHwX3ngfPaad0pmJ_Q&callback=initMap`;
      script.defer = true;
      script.async = true;

      script.onerror = () => {
        console.error('Error loading Google Maps API script');
      };

      document.head.appendChild(script);
    }
  });

  return (
    <Box
      // ml={{ base: '2%', md: '10%', lg: '16%' }}
      mr={{ base: '2%', md: '5%', lg: '9%' }} mt={'1%'} 
      display={'flex'}
      flexDirection={'column'}
      >
      <Box>
        <div className={styles.contentHeader}>
          <div>
            <h1 style={{fontSize:'30px', fontWeight:'bold'}}>Geolocation</h1>
          </div>
        </div>
      </Box>
      <Box>
        <div id="map" style={{ height: '80vh', width: '100%' }}></div>
      </Box>
    </Box>
  );
};

export default Geomap