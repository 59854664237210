import axios from "axios";

const baseURL = 'https://seahorse-app-2-3o2pf.ondigitalocean.app/election';

export const sendQueryToBackend = async (input, customerId) => {
    try {
      console.log(customerId)
      // Make a POST request to the backend API with the user query
      const response = await axios.post('https://ai1.ambicam.com:5500/generate_query', {
        user_input: input,
        customer_id: customerId
      });
      // Return the bot response from the backend
      console.log(response.data.beautified_sql_result)
      return response.data.beautified_sql_result;
    } catch (error) {
      // Handle error if API call fails
      console.error('Error fetching response:', error);
      throw error; // Re-throw the error for the caller to handle
    }
  };
  
  export const chatHistory = async () => {
    try {
      console.log("surekha");
      // Make a GET request to the backend API with the user query
      const response = await axios.get('https://goagpt.vmukti.com/export')
  
      // Return the response from the backend
      console.log(response)
      return response.data;
    } catch (error) {
      // Handle error if API call fails
      console.error('Error fetching response:', error);
      throw error; // Re-throw the error for the caller to handle
    }
  };