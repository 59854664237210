import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Text,
  Badge,
  Image,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  VStack,
  Flex,
  Select,
  FormControl,
  FormLabel,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Checkbox,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Heading,
  Divider,
  Radio,
  RadioGroup
} from '@chakra-ui/react';
import axios from 'axios';
import { FiMoreVertical, FiShare, FiTrash2, FiSettings, FiChevronLeft, FiChevronRight, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import LineCrossingDetection from './LineCrossing';
import CustomerTrafficStats from './CustomerTrafficStats';
import Humanoid from './Humanoid';
import FaceDetection from './FaceDetection';
import AreaDetection from './AreaDetection';
import MotionDetection from './MotionDetection';
import VideoSetup from './Media/VideoSetup';
import ImageSetup from './Media/ImageSetup';
import ISPSetup from './Media/ISPSetup';
import PrivacyMask from './Media/PrivacyMask';
import OSDSetup from './Media/OSDSetup';
import LocalSetup from './NetworkSetup/LocalSetup';
import RTMPSetup from './NetworkSetup/RTMPSetup';
import FTPSetup from './NetworkSetup/FTPSetup';
import SimNetwork from './NetworkSetup/SimNetwork';
import UAODetection from './UAODetection'
import MODetection from './MODetection'
import TimeSetup from './TimeSetup'
import DeviceInfo from './DeviceInfo'


const Psettings = ({deviceid}) => {
 
 
    const [selectedSystem, setSelectedSystemOption] = useState('Time');
    const [data, setData] = useState('');
    const [isp, setISP] = useState('');
    const [networkInterface, setNetworkInterface] = useState('');
    const [dnsSetting, setDnsSetting] = useState('');
    const [macaddress, setMacAddress] = useState('');
    const [error, setError] = useState(null);

    const [selectedSpeed, setSelectedSpeed] = useState('1');
    const [selectedCruise, setSelectedCruise] = useState('all round');
    const [presetValue, setPresetValue] = useState('');
    const [zoomValue, setZoomValue] = useState(50); // State for zoom value (default: 50)
    const [focusValue, setFocusValue] = useState(50); // State for focus value (default: 50)
    const [apertureValue, setApertureValue] = useState(50); // State for aperture value (default: 50)
    const [selectedMediaOption, setSelectedMediaOption] = useState('Video Setup');
    const [selectedNetworkOption, setSelectedNetworkOption] = useState('Local Setup');
    const [selectedAIOption, setSelectedAIOption] = useState('Motion Detection');
    const [currentTab, setCurrentTab] = useState('live');

 
      const [microphoneType, setMicrophoneType] = useState('');
      const [profile, setProfile] = useState('');
      const [encodeType, setEncodeType] = useState('');
      const [stream, setStream] = useState('');
      const [resolution, setResolution] = useState('');
      const [bitrateControlType, setBitrateControlType] = useState('');
      const [channelName, setChannelName] = useState('');
      const [bps, setBps] = useState('');
      const [fps, setFps] = useState('');

      const [isChecked, setIsChecked] = useState(false);
  
      const [contrast, setContrast] = useState(50);
      const [brightness, setBrightness] = useState(50);
      const [saturation, setSaturation] = useState(50);
      const [hue, setHue] = useState(50);
      const [sharpen, setSharpen] = useState(50);
  
      const [flipChecked, setFlipChecked] = useState(false);
      const [mirrorChecked, setMirrorChecked] = useState(false);
  
    const [checkedItems, setCheckedItems] = useState({
      Timer: false,
      Motion: false,
      Human: false,
      Face: false,
      Linecross: false,
      RegionEnter: false,
      RegionExit: false,
      Intrude: false,
      Unattend: false,
      ObjRemove: false,
    });

    useEffect(() => {
      const fetchData = async () => {
        try {
          
          const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/get-videoSettings',{
            params: {
              deviceid: deviceid
            }
          });
          setData(response.data);
        } catch (error) {
          setError(error);
        }
      };
     
      fetchData();
    }, [deviceid]); // Empty dependency array means this effect runs once after the initial render
   
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/isp-setting',{
            params: {
              deviceId: deviceid
            }}
          );
          setIsChecked(response.data);
        } catch (error) {
          setError(error);
        }
      };
 
      fetchData();
    }, []); // Empty dependency array means this effect runs once after the initial render    
 
    const handleSelectAll = () => {
      const allChecked = Object.keys(checkedItems).reduce((items, item) => {
        items[item] = true;
        return items;
      }, {});
    
      setCheckedItems(allChecked);
    };
 
    const handleTabChange = (tab) => {
      setCurrentTab(tab);
    };
 
    const [dateFormat, setDateFormat] = useState('');
    const [timeFormat, setTimeFormat] = useState('');
    const [datetimeOverlayEnabled, setDatetimeOverlayEnabled] = useState('');
    const [channelNameOverlayEnabled, setChannelNameOverlayEnabled] = useState('');

    const handleDateFormatChange = (e) => setDateFormat(e.target.value);
    const handleTimeFormatChange = (e) => setTimeFormat(e.target.value);
    const handleDatetimeOverlayEnabledChange = (e) => setDatetimeOverlayEnabled(e.target.checked);
    const handleChannelNameOverlayEnabledChange = (e) => setChannelNameOverlayEnabled(e.target.checked);
    const handleOSDClick = async () => {
      const parsedTimeFormat = parseInt(timeFormat, 10) || parseInt(data.datetimeOverlay.timeFormat, 10);

      const requestBody = {
        datetimeOverlay: {
          enabled: datetimeOverlayEnabled,
          dateFormat: dateFormat || data.datetimeOverlay.dateFormat,
          timeFormat: parsedTimeFormat,
          regionX: data.datetimeOverlay.regionX,
          regionY: data.datetimeOverlay.regionY,
          displayWeek: data.datetimeOverlay.displayWeek,
        },
        channelNameOverlay: {
          enabled: channelNameOverlayEnabled,
          regionX: data.channelNameOverlay.regionX,
          regionY: data.channelNameOverlay.regionY,
        },
        deviceIDOverlay:{enabled:false,regionX:0,regionY:0}
      };
      console.log(requestBody.datetimeOverlay.timeFormat)
      try {
        const response = await axios.put('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/osd-settings', requestBody,{
          params: {
            deviceId: deviceid
          }},
        );
        console.log('API response:', response);
      } catch (error) {
        console.error('Error making API call:', error);
      }
    }

    const renderSystemSetup = () => {
      switch (selectedSystem) {
        case 'Time':
          return (
            <TimeSetup deviceId={deviceid} />
          );
          case 'Device Info':
            return (
            <DeviceInfo deviceId={deviceid} />
          );
        default:
          return null;
      }
    }

    const renderMediaContent = () => {
      switch (selectedMediaOption) {
        case 'Video Setup':
          return (
            <VideoSetup deviceId={deviceid}/>
          );
        case 'Image Setup':
          return (
            <ImageSetup deviceId={deviceid}/>
          );
        case 'ISP':  // pending
          return (
            <ISPSetup deviceId={deviceid}/>
          );
        case 'Privacy Mask': //pending
          return (
            <PrivacyMask deviceId={deviceid}/>
            
          );
        case 'OSD':
          return (
          <OSDSetup deviceId={deviceid}/>
          );
        default:
          return null;
      }
    };
 
  const renderNetworkContent = () => {
    console.log("network")
    switch (selectedNetworkOption) {
      case 'Local Setup':
        return (
          <LocalSetup deviceId={deviceid}/>
          
        );
      case 'RTMP Setup':
        return (
          <RTMPSetup deviceId={deviceid}/>
         
        );
      case 'FTP Setting':
        return (
          <FTPSetup deviceId={deviceid}/>
         
        );
      case '4G Network Settings':
        return (
          <SimNetwork deviceId={deviceid}/>
        );
      default:
        return null;
    }
  }
 
  const renderAIContent = () => {
    switch(selectedAIOption) {
      case "Motion Detection":
        return  (
          <MotionDetection deviceId={deviceid}/> 
        );
      case "Humanoid Detection":
        return (
          <Humanoid deviceId={deviceid}/>
        );
      case "Face Detection":
        return  (
          <FaceDetection deviceId={deviceid}/>
        );
      case "Line Crossing Detection":
        return (
          <LineCrossingDetection deviceId={deviceid}/>
        );
      case "Area Detection":
        return  (
          <AreaDetection deviceId={deviceid}/> 
        );
      case "Customer Traffic Statistics":
        return (
          <CustomerTrafficStats deviceId={deviceid}/>
        );
      case "Unattend Baggage Detection":
        return  (
          <UAODetection deviceId={deviceid}/>
        );
      case "Missing Object Detection":
        return  (
          <MODetection deviceId={deviceid}/>
        );
      case "Alarm Schedule":
        return  (
          <Text>Alarm Schedule</Text>
        );
      case "Detection Area":
        return  (
          <Text>Detection Area</Text>
        );
      case "AlarmTone":
        return  (
          <Text>AlarmTone</Text>
        );
    };
  };

  return (
    <>
            <Tabs isFitted variant="enclosed">
              <TabList mb="1em">
                {/* <Tab onClick={() => handleTabChange("live")}>Live</Tab> */}
                <Tab onClick={() => handleTabChange("setup")}>Setup</Tab>
              </TabList>

              <TabPanels>
               
                <TabPanel>
                  <Accordion allowToggle defaultIndex={[0]}>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            Media
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Flex>
                          <Box w="20%" borderRight="1px solid #ddd" p={4}>
                            <Stack spacing={4}>
                              {[
                                "Video Setup",
                                "Image Setup",
                                "ISP",
                                "Privacy Mask",
                                "OSD",
                              ].map((option) => (
                                <Button
                                  key={option}
                                  variant="ghost"
                                  isFullWidth
                                  justifyContent="start"
                                  onClick={() => setSelectedMediaOption(option)}
                                  bg={
                                    selectedMediaOption === option
                                      ? "blue.100"
                                      : "transparent"
                                  }
                                  _hover={{ bg: "blue.50" }}
                                >
                                  {option}
                                </Button>
                              ))}
                            </Stack>
                          </Box>
                          <Box w="80%" p={4}>
                            {renderMediaContent()}
                          </Box>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            AI Detection
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                      <Flex>
                          <Box w="25%" borderRight="1px solid #ddd" p={4}>
                            <Stack spacing={4}>
                              {[
                                "Motion Detection",
                                "Humanoid Detection",
                                "Face Detection",
                                "Line Crossing Detection",
                                "Area Detection",
                                "Customer Traffic Statistics",
                                "Unattend Baggage Detection",
                                "Missing Object Detection",
                                // "Alarm Schedule",
                                // "Detection Area",
                                // "AlarmTone",
                              ].map((AIoption) => (
                                <Button
                                  key={AIoption}
                                  variant="ghost"
                                  isFullWidth
                                  justifyContent="start"
                                  onClick={() => setSelectedAIOption(AIoption)}
                                  bg={
                                    selectedAIOption === AIoption
                                      ? "blue.100"
                                      : "transparent"
                                  }
                                  _hover={{ bg: "blue.50" }}
                                >
                                  {AIoption}
                                </Button>
                              ))}
                            </Stack>
                          </Box>
                          <Box w="80%" p={4}>
                            {renderAIContent()}
                          </Box>
                        </Flex>                      
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            Network Setup
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                      <Flex>
                          <Box w="20%" borderRight="1px solid #ddd" p={4}>
                            <Stack spacing={4}>
                              {[
                                "Local Setup",
                                "RTMP Setup",
                                "FTP Setting",
                                "4G Network Settings",
                              ].map((netwrokoption) => (
                                <Button
                                  key={netwrokoption}
                                  variant="ghost"
                                  isFullWidth
                                  justifyContent="start"
                                  onClick={() => setSelectedNetworkOption(netwrokoption)}
                                  bg={
                                    selectedNetworkOption === netwrokoption
                                      ? "blue.100"
                                      : "transparent"
                                  }
                                  _hover={{ bg: "blue.50" }}
                                >
                                  {netwrokoption}
                                </Button>
                              ))}
                            </Stack>
                          </Box>
                          <Box w="80%" p={4}>
                            {renderNetworkContent()}
                          </Box>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            System Setup
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Flex>
                          <Box w="20%" borderRight="1px solid #ddd" p={4}>
                            <Stack spacing={4}>
                              {[
                                "Time",
                                "Device Info",
                              ].map((systemoption) => (
                                <Button
                                  key={systemoption}
                                  variant="ghost"
                                  isFullWidth
                                  justifyContent="start"
                                  onClick={() => setSelectedSystemOption(systemoption)}
                                  bg={
                                    selectedSystem === systemoption
                                      ? "blue.100"
                                      : "transparent"
                                  }
                                  _hover={{ bg: "blue.50" }}
                                >
                                  {systemoption}
                                </Button>
                              ))}
                            </Stack>
                          </Box>
                          <Box w="80%" p={4}>
                            {renderSystemSetup()}
                          </Box>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </TabPanel>
               
              </TabPanels>
            </Tabs>
         
    </>
  );
};

export default Psettings;