import React from 'react';
import styles from './SignUpPage.module.css';
import Header from './Header';
import LoginForm from './SignUpPageForm';
import SocialSign from './SocialSign';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const navigate = useNavigate();

  const handleLoginClick =() => {
    navigate('/');
  }
  return (
    <div className={styles.loginPage}>
      <div className={styles.loginContent}>
        <div className={styles.loginSection}>
          <Header />
          <main className={styles.mainContent}>
            <div className={styles.formContainer}>
              <h1 className={styles.title}>Sign Up</h1>
              <p className={styles.supportingText}>
                Lets's Get Start and Connect with US..
              </p>
              <LoginForm />
              <div className={styles.signupPrompt}>
                <span>Already have an account?</span>
                <button className={styles.signupLink}  onClick={handleLoginClick}>Login</button>
              </div>
            </div>
          </main>
          {/* <SocialSign/> */}
          <Footer />
        </div>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a97765940e63e717f826a53e36f099412c1f2713d32db196da2c2d070ec999e?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.sideImage} />
      </div>
    </div>
  );
}

export default LoginPage;