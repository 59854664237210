import { useState, useEffect } from 'react';
import {
  Box, useColorModeValue, CloseButton,
  Flex,
  Heading,
  Text,
  Button,
  IconButton,
  Spacer,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Avatar,
  AvatarBadge,
  Grid,
  GridItem,
  Image,
  Divider, Icon,
  VStack,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  HStack,
  Link,
  List,
  ListItem
} from '@chakra-ui/react';
import { FiMenu, FiSettings, FiBell, FiX } from 'react-icons/fi';
import { BsCameraVideo, BsBell, BsCloudArrowDown, BsPeople, BsFillCarFrontFill } from 'react-icons/bs';
import { TbFaceId, TbArrowsDiff } from 'react-icons/tb'; // Replace with the actual import for TbFaceId and TbArrowsDiff
import { AiOutlineHeatMap } from 'react-icons/ai';
import { GrMultiple } from 'react-icons/gr';
import MobileHeader from '../components/MobileHeader';
import DesktopHeader from '../components/DesktopHeader';

const LinkItems = [
  { name: 'Cameras', icon: BsCameraVideo },
  { name: 'Multiple', icon: GrMultiple, path: '/multiple' },
  { name: 'Events', icon: BsBell },
  { name: 'Archive Export', icon: BsCloudArrowDown },
  { name: 'Faces', icon: TbFaceId },
  { name: 'Heatmap', icon: AiOutlineHeatMap },
  { name: 'People', icon: BsPeople },
  { name: 'Visitors', icon: TbArrowsDiff },
  { name: 'ANPR', icon: BsFillCarFrontFill },
  { name: 'Settings', icon: FiSettings },
];

const RefundAndCancellation = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const SidebarContent = ({ onClose, ...rest }) => {
    return (
      <Box
        bg={'gray.100'}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        w={{ base: 'full', md: '5rem' }}
        pos="fixed"
        h="full"
        {...rest}
      >
        {/* <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
            Logo
          </Text>
        </Flex> */}
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
        {LinkItems.map((link) => (

          <NavItem key={link.name} icon={link.icon}>
            <Link href={link.path}>{link.name}</Link>
          </NavItem>

        ))}
      </Box>
    );
  };

  const NavItem = ({ icon, children, ...rest }) => {
    return (
      <Box
        as="a"
        href="#"
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
      >
        <Flex
          direction="column" // Set direction to 'column'
          align="center"
          pt="4"
          pb="4"
          pl="6"
          pr="6"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'cyan.400',
            color: 'white',
          }}
          {...rest}
        >
          {icon && (
            <Icon
              fontSize="20" // Set icon size to 20
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          <Text fontSize="10">{children}</Text> {/* Set text size to 10 */}
        </Flex>
      </Box>
    );
  };


  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      {/* Mobile Header */}
      {isMobile && (

        <MobileHeader headerText="Dashboard" />

      )}

      {/* Desktop Header */}
      {!isMobile && (

        <Box position="fixed" top={0} left={0} width="100%" zIndex={9}>
          <DesktopHeader />
        </Box>

      )}



      <Box ml={{ base: 0, md: '5rem' }} p="0">

        <Box bg="gray.100" minH="100vh" >

          {/* Mobile Header */}
          {isMobile && (

            <MobileHeader headerText="Dashboard" />

          )}


          {/* Main Content */}
          <Box padding={75} overflowY="auto" maxH="calc(100vh - 60px)">

            <Heading as="h1" mb={4} color='#00AEEF'>
              Refund and Cancellation Policy
            </Heading>

            <Text fontSize="sm" mb={4} color="gray.600">
              Last Updated: August 10th, 2024
            </Text>
            <Text fontSize="sm" mb={6} color="gray.600">
              This policy applies to all Ambicam products.
            </Text>

            <Box mb={6}>
              <Heading as="h2" size="lg" mb={2}>
                1. General Refund Policy
              </Heading>
              <Text mb={2}>
                Ambicam strives to provide the best products and services to our customers. If you are not satisfied with a product or service, we offer a refund under the following conditions:
              </Text>
              <List spacing={2} mb={2}>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    A)
                  </Text>{" "}
                  The refund request is made within 30 days of purchase.
                </ListItem>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    B)
                  </Text>{" "}
                  The product is in its original condition and packaging.
                </ListItem>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    C)
                  </Text>{" "}
                  Services that have not been utilized or consumed.
                </ListItem>
              </List>
            </Box>

            <Box mb={6}>
              <Heading as="h2" size="lg" mb={2}>
                2. Non-Refundable Items
              </Heading>
              <Text mb={2}>
                Certain items are non-refundable. These include:
              </Text>
              <List spacing={2} mb={2}>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    A)
                  </Text>{" "}
                  Downloadable software products.
                </ListItem>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    B)
                  </Text>{" "}
                  Subscription services once accessed or used.
                </ListItem>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    C)
                  </Text>{" "}
                  Any item not in its original condition, damaged, or missing parts for reasons not due to our error.
                </ListItem>
              </List>
            </Box>

            <Box mb={6}>
              <Heading as="h2" size="lg" mb={2}>
                3. Process for Requesting a Refund
              </Heading>
              <Text mb={2}>
                To request a refund, please follow these steps:
              </Text>
              <List spacing={2} mb={2}>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    A)
                  </Text>{" "}
                  Contact our customer service team at support@ambicam.com.
                </ListItem>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    B)
                  </Text>{" "}
                  Provide your order number and reason for the refund request.
                </ListItem>
                <ListItem>
                  <Text as="span" fontWeight="bold">
                    C)
                  </Text>{" "}
                  If the refund is approved, we will process it and notify you of the status via email.
                </ListItem>
              </List>
            </Box>

            <Box mb={6}>
              <Heading as="h2" size="lg" mb={2}>
                4. Cancellation Policy
              </Heading>
              <Text mb={2}>
                If you need to cancel a service, please notify us at least 24 hours in advance. Cancellations made less than 24 hours in advance may not be eligible for a refund.
              </Text>
            </Box>

            <Box mb={6}>
              <Heading as="h2" size="lg" mb={2}>
                5. Contact Information
              </Heading>
              <Text mb={2}>
                If you have any questions about our refund and cancellation policy, please contact us at support@ambicam.com.
              </Text>
            </Box>

            <Divider my={6} />
          </Box>

        </Box>
      </Box>
    </Box>

  );
};
export default RefundAndCancellation;