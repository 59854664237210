// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MetricGroup_metricGroup__R18lF {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: 200px;
    margin-bottom: 12px;
  }
  
  @media (max-width: 991px) {
    .MetricGroup_metricGroup__R18lF {
      flex-direction: column;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Reports/MetricGroup.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE;MACE,sBAAsB;IACxB;EACF","sourcesContent":[".metricGroup {\r\n    display: flex;\r\n    gap: 12px;\r\n    flex-wrap: wrap;\r\n    margin-top: 20px;\r\n    margin-left: 200px;\r\n    margin-bottom: 12px;\r\n  }\r\n  \r\n  @media (max-width: 991px) {\r\n    .metricGroup {\r\n      flex-direction: column;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"metricGroup": `MetricGroup_metricGroup__R18lF`
};
export default ___CSS_LOADER_EXPORT___;
